import * as _ from 'lodash';
import {
	FETCH_CUSTOMER_PRICELIST, FETCH_CUSTOMER_PRICELIST_FULFILLED, FETCH_CUSTOMER_PRICELIST_REJECTED,
	FETCH_CUSTOMER_MATERIAL_PRICELIST, FETCH_CUSTOMER_MATERIAL_PRICELIST_FULFILLED, FETCH_CUSTOMER_MATERIAL_PRICELIST_REJECTED
} from '../actions';

export default (state = {
	data: null,
	fetching: false,
	fetched: false,
	error: null,
	customerMaterialPrice: null
}, action) => {
	switch (action.type) {
		case FETCH_CUSTOMER_PRICELIST:
			return { ...state, fetching: true, fetched: false, error: null }
		case FETCH_CUSTOMER_PRICELIST_FULFILLED:
			return {
				...state, fetching: false, fetched: true, error: null,
				data: action.payload
			};
		case FETCH_CUSTOMER_PRICELIST_REJECTED:
			return { ...state, fetching: false, fetched: false, error: action.payload, data: null };
		case FETCH_CUSTOMER_MATERIAL_PRICELIST:
			return { ...state, fetching: true, fetched: false, error: null }
		case FETCH_CUSTOMER_MATERIAL_PRICELIST_FULFILLED:
			return {
				...state, fetching: false, fetched: true, error: null,
				customerMaterialPrice: action.payload

			};
		case FETCH_CUSTOMER_MATERIAL_PRICELIST_REJECTED:
			return { ...state, fetching: false, fetched: false, error: action.payload, customerMaterialPrice: null };
		default:
			return state;
	}
}
