export { country, numFormat };
const country = value => {
  switch (value.toLowerCase()) {
    case "philippines":
      return "PH";
    case "thailand":
      return "TH";
    case "vietnam":
      return "VT";
    case "malaysia":
      return "MY";
    case "indonesia":
      return "ID";
    default:
      return "PH";
    // throw Error("country name must be defined");
  }
};
const numFormat = (type, value) => {
  value = value ?? 0.00;
  switch (type) {
    case "dot/comma":
      return value
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    case "comma":
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    default:
      throw Error("number format and value must be defined");
  }
};
