import Axios from 'axios'
import config from '../config';

// Must have own file.
export const POST_CONTACT = 'POST_CONTACT'
export const POST_CONTACT_FULFILLED = 'POST_CONTACT_FULFILLED'
export const POST_CONTACT_REJECTED = 'POST_CONTACT_REJECTED'

export const submitContact = (data) => {
  return dispatch => {
    dispatch({
      type: POST_CONTACT,
      payload: {}
    })
    return Axios.post(`${config.REACT_APP_API_BASE_URL}/inquiry-message`, { ...data })
      .then(function (response) {
        dispatch({
          type: POST_CONTACT_FULFILLED,
          payload: response.data
        })
        return { error: false }
      })
      .catch(function (error) {
        dispatch({
          type: POST_CONTACT_REJECTED,
          payload: error
        })
        return { error: true }
      })
  }
}
