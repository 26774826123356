import * as _ from 'lodash';
import {
    FETCH_PERMISSIONS,
    FETCH_PERMISSIONS_FULFILLED,
    FETCH_PERMISSIONS_REJECTED
} from '../actions';

export default (state = {
    data: [],
    size: 0,
    fetching: false,
    fetched: false,
    error: null
}, action) => {
    switch (action.type) {
        case FETCH_PERMISSIONS:
            return { ...state, fetching: true, fetched: false, error: null }
        case FETCH_PERMISSIONS_FULFILLED:
            const data = action.payload
            return { ...state, fetching: false, fetched: true, error: null, data, size: _.size(data) };
        case FETCH_PERMISSIONS_REJECTED:
            return { ...state, fetching: false, fetched: false, error: action.payload, data: [] };
        default:
            return state;
    }
}
