import axios from 'axios';
import * as _ from 'lodash';

import config from '../config';
import { headers } from '../utilities'

export const SUBMIT_ORDER = 'SUBMIT_ORDER';
export const SUBMIT_ORDER_FULFILLED = 'SUBMIT_ORDER_FULFILLED';
export const SUBMIT_ORDER_REJECTED = 'SUBMIT_ORDER_REJECTED';

export const FETCH_ORDER_HISTORY = 'FETCH_ORDER_HISTORY';
export const FETCH_ORDER_HISTORY_FULFILLED = 'FETCH_ORDER_HISTORY_FULFILLED';
export const FETCH_ORDER_HISTORY_REJECTED = 'FETCH_ORDER_HISTORY_REJECTED';

export const SUBMIT_CUSTOMER_MATERIAL = 'SUBMIT_CUSTOMER_MATERIAL';
export const SUBMIT_CUSTOMER_MATERIAL_FULFILLED = 'SUBMIT_CUSTOMER_MATERIAL_FULFILLED';
export const SUBMIT_CUSTOMER_MATERIAL_REJECTED = 'SUBMIT_CUSTOMER_MATERIAL_REJECTED';

export const submitOrder = (data => dispatch => {
  dispatch({
    type: SUBMIT_ORDER,
    payload: {}
  });
  return axios.post(`${config.REACT_APP_API_BASE_URL}/orders`, { ...data }, { headers: headers() })
    .then(response => {
      dispatch({
        type: SUBMIT_ORDER_FULFILLED,
        payload: response.data
      });
      return response;
    })
    .catch(err => {
      dispatch({
        type: SUBMIT_ORDER_REJECTED,
        payload: err
      });
      return err;
    });
});

export const fetchOrderHistory = (transactionId => dispatch => {
  dispatch({
    type: FETCH_ORDER_HISTORY,
    payload: {}
  });
  return axios.get(`${config.REACT_APP_API_BASE_URL}/order/${transactionId}`, { headers: headers() })
    .then(response => {
      dispatch({
        type: FETCH_ORDER_HISTORY_FULFILLED,
        payload: response.data
      });
      return response;
    })
    .catch(err => {
      dispatch({
        type: FETCH_ORDER_HISTORY_REJECTED,
        payload: err
      });
      return err;
    });
});

export const createCustomerMaterial = (params => dispatch => {
  dispatch({
    type: SUBMIT_CUSTOMER_MATERIAL,
    payload: {}
  });
  return axios.post(`${config.REACT_APP_API_BASE_URL}/create-customer-material-link`, {}, { headers: headers() })
    .then(response => {
      dispatch({
        type: SUBMIT_CUSTOMER_MATERIAL_FULFILLED,
        payload: response.data
      });
      return response;
    })
    .catch(err => {
      dispatch({
        type: SUBMIT_CUSTOMER_MATERIAL_REJECTED,
        payload: err
      });
      return err;
    });
});

