import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Input, Button, Layout, Icon, Card, Modal } from 'antd'
import { resetPassword } from '../../actions'
import error from "../../assets/icons/error-icon.png";
import success from "../../assets/icons/check-icon.png";

// Import CSS
import './ResetPassword.scss'

class ResetPassword extends Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    loading: false
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true })
        const urlParams = new URLSearchParams(window.location.search);

        const params = {
          password: values.password,
          password_confirmation: values.password_confirmation,
          token: this.props.match.params.token,
          email: this.props.match.params.email,
        }

        this.props.resetPassword(params).then(response => {
          if (response.error) {
            Modal.error({
              title: 'Unable to process the request. Please try again.',
              content: '',
              icon: <img src={error} alt="error" />
            })
          } else {
            Modal.success({
              title: 'Success! Your password has been changed. You will be redirected to home page. Thank you.',
              content: '',
              icon: <img src={success} alt="success" />,
              onOk: () => this.props.history.push('/?modalLogin=true')
            })
            this.props.form.resetFields()
          }
          this.setState({ loading: false })
        })
      }
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback(<p>The new password and confirmation password do not match.</p>);
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  render() {
    const { loading } = this.state
    const { form: { getFieldDecorator } } = this.props
    return (
      <Layout>
        <Layout.Content>
          <div className='confirm-password-container'>
            <Card className='card-width'>
              <h1>Type New Password</h1>
              <Form onSubmit={this.handleSubmit}>
                <Form.Item label="New" hasFeedback>
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your new password.',
                      },
                      {
                        min: 8,
                        message: 'The password must be at least 8 characters.',
                      },
                      {
                        validator: this.validateToNextPassword,
                      },
                    ],
                  })(<Input.Password />)}
                </Form.Item>
                <Form.Item label="Retype New" hasFeedback>
                  {getFieldDecorator('password_confirmation', {
                    rules: [
                      {
                        required: true,
                        message: 'Please retype your password.',
                      },
                      {
                        min: 8,
                        message: 'The password must be at least 8 characters.',
                      },
                      {
                        validator: this.compareToFirstPassword,
                      },
                    ],
                  })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                </Form.Item>
                <Form.Item>
                  <Button className='btn-small btn-primary' loading={loading} style={{ width: "100%" }} htmlType="submit">
                    {loading ? null : "Submit"}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </Layout.Content>
      </Layout >
    );
  }
}


const ResetPasswordForm = Form.create({ name: 'reset_password_form' })(ResetPassword)
function mapStateToProps(state) {
  return {
  }
}
function mapDispatchToProps(dispatch) {
  return {
    resetPassword: (data) => dispatch(resetPassword(data))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm)
