import axios from 'axios';
import * as _ from 'lodash';

import config from '../config';
import { headers } from '../utilities'

export const FETCH_PRODUCT_PRICE_LIST = 'FETCH_PRODUCT_PRICE_LIST';
export const FETCH_PRODUCT_PRICE_LIST_FULFILLED = 'FETCH_PRODUCT_PRICE_LIST_FULFILLED';
export const FETCH_PRODUCT_PRICE_LIST_REJECTED = 'FETCH_PRODUCT_PRICE_LIST_REJECTED';

export const FETCH_PRODUCT_BY_ID = 'FETCH_PRODUCT_BY_ID';
export const FETCH_PRODUCT_BY_ID_FULFILLED = 'FETCH_PRODUCT_BY_ID_FULFILLED';
export const FETCH_PRODUCT_BY_ID_REJECTED = 'FETCH_PRODUCT_BY_ID_REJECTED';

export const fetchProductPriceList = (filters = {}) => dispatch => {
  const params = _.mapValues(Object.assign({}, filters), filter => filter.join ? filter.join(',') : filter)
  dispatch({
    type: FETCH_PRODUCT_PRICE_LIST,
    payload: {}
  });
  return axios.get(`${config.REACT_APP_API_BASE_URL}/customer-pricelists`, { headers: headers(), params })
    .then(response => {
      dispatch({
        type: FETCH_PRODUCT_PRICE_LIST_FULFILLED,
        payload: response.data
      });
      return response;
    })
    .catch(err => {
      dispatch({
        type: FETCH_PRODUCT_PRICE_LIST_REJECTED,
        payload: err
      });
      return err;
    });
}


export const fetchProductById = (params) => dispatch => {
  dispatch({
    type: FETCH_PRODUCT_BY_ID,
    payload: {}
  });
  return axios.get(`${config.REACT_APP_API_BASE_URL}/customer-pricelists/${params.material_number}`, { headers: headers(), params: { 
    storage_location: params.storage_location,
    sold_to: params.sold_to,
    ship_to: params.ship_to
  } })
    .then(response => {
      dispatch({
        type: FETCH_PRODUCT_BY_ID_FULFILLED,
        payload: {}
      });
      return response.data;
    })
    .catch(err => {
      dispatch({
        type: FETCH_PRODUCT_BY_ID_REJECTED,
        payload: err
      });
      return err;
    });
}

