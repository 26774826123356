import axios from 'axios';

import config from '../config';
import { headers } from '../utilities'

export const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS';
export const FETCH_PERMISSIONS_FULFILLED = 'FETCH_PERMISSIONS_FULFILLED';
export const FETCH_PERMISSIONS_REJECTED = 'FETCH_PERMISSIONS_REJECTED';

export const fetchPermissions = () => dispatch => {
    dispatch({
        type: FETCH_PERMISSIONS,
        payload: {}
    });
    return axios.get(`${config.REACT_APP_API_BASE_URL}/my-permissions`, { headers: headers() })
        .then(response => {
            dispatch({
                type: FETCH_PERMISSIONS_FULFILLED,
                payload: response.data
            });
        })
        .catch(err => {
            dispatch({
                type: FETCH_PERMISSIONS_REJECTED,
                payload: err
            })
        });
}