import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  reset,
  change,
} from "redux-form";
import * as _ from "lodash";
import {
  Layout,
  Row,
  Col,
  Form,
  Input,
  List,
  Card,
  Button,
  Modal,
  Spin,
  Icon,
  Skeleton,
  Result,
  message,
  Divider,
  DatePicker as DatePickerAntd
} from "antd";
import MediaQuery from "react-responsive";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DocumentMeta from "react-document-meta";
import moment from "moment";

import config from "../../config";
import {
  fetchProducts,
  fetchProductPriceList,
  fetchDrafts,
  fetchDistributor,
  fetchSoldTo,
  fetchShipTo,
  createProductOrder,
  fetchPermissions,
  saveAsDraft,
  fetchPoRefNo,
  postPoRefNo,
  postFeedback,
  fetchDraftById,
  isCustomerPoNoUnique,
  fetchProductById,
  submitOrder,
  fetchOrderHistory,
  createCustomerMaterial,
  fetchStorageLocation,
  fetchCustomerPriceList,
  fetchCustomerMaterialPriceList,
  uploadOrderAttachment
} from "../../actions";
import FormField from "../shared/FormField";

import ModalDeliveryDetails from "./Modals/deliveryDetails";
import ModalSapOrderSummary from "./Modals/sapOrderSummary";
import ModalAttachment from '../OrderStatus/components/ModalAttachment';
import warningIcon from "../../assets/icons/warning-icon.png";

import rate1 from "../../assets/svg/rate-1.svg";
import rate2 from "../../assets/svg/rate-2.svg";
import rate3 from "../../assets/svg/rate-3.svg";
import rate4 from "../../assets/svg/rate-4.svg";
import rate5 from "../../assets/svg/rate-5.svg";
import error from "../../assets/icons/error-icon.png";
import success from "../../assets/icons/check-icon.png";
import { numFormat } from "../../utilities/format";

import "./Order.scss";

const feedbackEmoji = [
  {
    rate: 1,
    svg: rate1,
  },
  {
    rate: 2,
    svg: rate2,
  },
  {
    rate: 3,
    svg: rate3,
  },
  {
    rate: 4,
    svg: rate4,
  },
  {
    rate: 5,
    svg: rate5,
  },
];

const renderDatepicker = (field) => {
  const {
    input,
    label,
    selected,
    minDate,
    meta: { touched, error, warning },
  } = field;
  return (
    <div style={{ height: 50 }}>
      <DatePicker
        {...input}
        {...field}
        placeholderText={label}
        dateFormat="yyyy/MM/dd"
        minDate={minDate}
        selected={selected}
        value={selected}
      />
      {touched &&
        ((error && <span style={{ color: "red" }}>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  );
};


const renderDatePicker2 = ({ input, meta: { touched, error, visited }, ...rest }) => {
  const handleChange = (dates, dateStrings) => {
    input.onChange(dateStrings);
  }

  const value = input.value && touched && visited ? moment(input.value) : rest.defaultValue;
  return (<div>
    <DatePickerAntd
      {...input}
      {...rest}
      onChange={handleChange}
      value={rest.formValues.quantity > 0 ? value : null}
    />
    {touched && error && <span>{error}</span>}
  </div>)
};

const renderInput = ({
  input,
  label,
  type,
  className,
  readOnly,
  meta: { touched, error, warning },
}) => (
  <div style={{ height: 50 }}>
    <input
      {...input}
      className={className}
      placeholder={label}
      readOnly={readOnly}
      type={type}
    />
    {touched &&
      ((error && <span style={{ color: "red" }}>{error}</span>) ||
        (warning && <span>{warning}</span>))}
  </div>
);

const renderSelectField = ({
  input,
  className,
  defaultValue,
  meta: { touched, error },
  children,
  disabled,
}) => {
  return (
    <div>
      <select
        {...input}
        className={className}
        defaultValue={defaultValue}
        disabled={disabled}
        style={{
          color:
            input.value === null && input.value === "" && defaultValue === null && defaultValue === "" ? "#B2B2B2" : "#404040",
        }}
        value={input.value}
      >
        {children}
      </select>
      {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
  );
};

const formValidators = (value) => (value ? undefined : "Required");

const orderProductList = ({
  isInitializing,
  fields,
  prices,
  products,
  formValues,
  resetForm,
  handleSaveAsDraft,
  myPermissions,
  productOrderLoading,
  productsIsFetching,
  priceListIsFetching,
  isDraftLoading,
  renderSelectField,
  handlePriceCallback,
  distributor,
  customerPricelistFetching,
  orderSummary,
  onAttachmentModalShow
}) => {
  const totals = { cbm: 0, price: 0, quantity: 0, grossWeight: 0 };
  const orderedProducts = _.filter(
    formValues.orders,
    (order) => parseInt(order.quantity) > 0
  );

  if (orderedProducts.length > 0) {
    totals.price = _.chain(orderedProducts)
      .map(
        (product) => (product.material.price || 0) * parseInt(product.quantity)
      )
      .sum()
      .value();
    totals.quantity = _.chain(orderedProducts)
      .map((product) => parseInt(product.quantity))
      .sum()
      .value();
  }

  const handleQty = (e) => {
    if (e === null) {
      return false
    }
  };

  const handleDeliveryLeadTime = () => {
    return orderSummary?.delivery_details?.type === "delivery" ? 3 : 0;
  }

  const customerTag = distributor.customer.tagging || [];

  return (
    <List
      loading={productsIsFetching || priceListIsFetching || isInitializing || customerPricelistFetching}
      bordered
      itemLayout="horizontal"
      dataSource={
        products && formValues.orders && formValues.orders.length > 0
          ? fields
            .map((field) => field)
            .sort(
              (a, b) =>
                moment(b.created_at).unix() - moment(a.created_at).unix()
            )
          : []
      }
      renderItem={(field) => {
        const { material_variant_id } = _.get(formValues, field);

        const item = _.find(
          _.filter(
            _.filter(products, (product) => {
              return product && product.selling_price !== 0
            }),
            (product) => {
              return product && product.variant !== null
            }),
          { material_variant_id });
        if (!item) return <Fragment />;

        const { id } = item;

        const price = _.find(prices, (price) => {
          return parseInt(price.material_number, 10) === parseInt(item.material.material_number, 10)
        })?.price

        if (price) {
          item["material"]["price"] = price;
        }

        if (!price) return <Fragment />;

        const inList = orderedProducts.filter((orderedProducts) => {
          return item !== undefined
            ? orderedProducts.id === item.id : ""
        })

        const handleSelectSource = async (e, materialCode) => {
          const storageLocation = _.find(distributor.customer.source, (location) => {
            return parseInt(location.id, 10) === parseInt(e.target.value, 10)
          })

          handlePriceCallback(storageLocation.shipping_point, materialCode)
        }

        const disabledDate = (current) => {
          return current && current < moment().add((addedDate() - 1), 'days');
        }

        const availabilityDate = () => {
          return parseInt(_.get(formValues, field).quantity, 10) > 0 ? moment().add(addedDate(), 'days') : null;
        }

        const addedDate = () => {
          return item.tagging === "mto" ? item.availability_days : handleDeliveryLeadTime();
        }

        const image = item.material.image ? process.env.REACT_APP_FILES_URL + item.material.image : 'https://via.placeholder.com/100x100?text=No+Image';
        return (
          <List.Item className="product" key={id}>
            <MediaQuery minWidth={1224}>
              <Row
                gutter={24}
                type="flex"
                align="middle"
              >
                <Col span={2}>
                  <h3>
                    <img src={image} alt={item.material.description} width={80} style={{ height: 80, objectFit: 'contain' }} />

                  </h3>
                </Col>
                <Col span={customerTag && customerTag.includes('mto') ? 6 : 9} className="product-information">
                  <span
                    className="product-image"
                    style={{
                      backgroundImage: `url(${item && item.variant && item.variant.image
                        ? `${config.REACT_APP_FILES_URL}thumbs_${item.variant.image}`
                        : "https://via.placeholder.com/100x100?text=No+Image"
                        })`,
                    }}
                  />
                  <span className="product-id">
                    <h5>{item.material.material_number}</h5>
                    <h3>{item.material.description}</h3>
                  </span>
                </Col>
                <Col span={2} align="center">
                  {numFormat("dot/comma", (item.material.price + (item.material.price * 0.12))) || "0.00"}
                </Col>
                <Col span={2} align="center">
                  {numFormat("dot/comma", item.material.price) || "0.00"}
                </Col>
                <Col span={3} align="right">
                  {numFormat("dot/comma", (item.material.price + (item.material.price * 0.12)) *
                    inList.map((value) => {
                      return value.quantity || 0;
                    })
                  ) || "0.00"}
                </Col>
                <Col span={4} align="center">
                  {distributor.customer.source.length > 1 ?
                    (<Field
                      name={`${field}.source`}
                      placeholder="Select Source"
                      className="source-input"
                      component={renderSelectField}
                      onChange={(e) => handleSelectSource(e, item.material.material_number)}
                      defaultValue={1}
                    >
                      {
                        distributor.customer.source.map((source) => {
                          return (<option value={source.id} className="select">
                            {source.description}
                          </option>)
                        })
                      }
                    </Field>) :

                    (<span>
                      {distributor.customer.source[0].description}
                    </span>)
                  }
                </Col>
                {(customerTag && customerTag.includes('mto')) && <Col span={3} align="center">
                  <Field component={renderDatePicker2}
                    name={`${field}.availability_date`}
                    disabledDate={disabledDate}
                    formValues={_.get(formValues, field)}
                    defaultValue={availabilityDate()}
                    value={availabilityDate()}
                    disabled={(_.get(formValues, field).quantity > 0 ? false : true) || item.tagging === "regular"}
                  />
                </Col>}
                <Col span={2} align="center">
                  <Field
                    className="quantity-input"
                    id={`${id}_${item.material.material_number}`}
                    type="number"
                    name={`${field}.quantity`}
                    min={0}
                    component={FormField}
                    onChange={handleQty}
                    disabled={
                      !myPermissions.some(
                        (r) => r.name === "distributor.orders.create"
                      )
                    }
                  />
                </Col>
              </Row>
            </MediaQuery>
            <MediaQuery maxWidth={1224} minWidth={601}>
              <Row
                type="flex"
                justify="space-between"
                align="top"
                className="product-info-mobile"
              >
                <Col span={24}>
                  <Row type="flex" justify="space-between" align="top">
                    <Col span={8}>
                      <Row type="flex" justify="space-between" align="top">
                        <Col span={9}>
                          <span
                            className="product-image"
                            style={{
                              backgroundImage: `url(${item && item.variant && item.variant.image
                                ? `${config.REACT_APP_FILES_URL}thumbs_${item.variant.image}`
                                : `"https://via.placeholder.com/100x100?text=No+Image"`
                                })`,
                            }}
                          />
                        </Col>
                        <Col span={15}>
                          <h5>{item.material.material_number}</h5>
                          <h3>{item.material.description}</h3>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={8}>
                      <Row justify="space-between" align="top">
                        <Col span={24}>
                          <label>AMOUNT (PHP VAT-EX)</label> <br />
                          <span>
                            &nbsp;
                            {numFormat("dot/comma", item.material.price) || "0.00"}
                          </span>
                        </Col>
                      </Row>
                      <Row justify="space-between" align="top">
                        <Col span={24}>
                          <label>ORDERED AMOUNT (PHP VAT-IN)</label> <br />
                          <span>
                            {numFormat("dot/comma", (item.material.price + (item.material.price * 0.12)) *
                              inList.map((value) => {
                                return value.quantity || 0;
                              })
                            ) || "0.00"}
                          </span>
                        </Col>
                      </Row>
                      <Row justify="space-between" align="top">
                        <Col span={24}>
                          <label>QTY.</label>
                          <br />
                          <Field
                            className="quantity-input"
                            id={`${id}_${item.material.material_code}`}
                            type="number"
                            name={`${field}.quantity`}
                            min={0}
                            component={FormField}
                            onChange={handleQty}
                            disabled={
                              !myPermissions.some(
                                (r) => r.name === "distributor.orders.create"
                              )
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={8}>
                      <Row type="flex" justify="space-between" align="top">
                        <Col span={24}>
                          <label>AMOUNT (PHP VAT-IN)</label> <br />
                          <span>
                            {numFormat("dot/comma", item.material.price + (item.material.price * 0.12)) || "0.00"}
                          </span>
                        </Col>
                      </Row>
                      <Row type="flex" justify="space-between" align="top">
                        <Col span={24}>
                          <label> SOURCE </label> <br />
                          {distributor.customer.source.length > 1 ?
                            (<Field
                              name={`${field}.source`}
                              placeholder="Select Source"
                              className="source-input"
                              component={renderSelectField}
                              onChange={(e) => handleSelectSource(e, item.material.material_number)}
                              defaultValue={1}
                            >
                              {
                                distributor.customer.source.map((source) => {
                                  return (<option value={source.id} className="select">
                                    {source.description}
                                  </option>)
                                })
                              }
                            </Field>) :

                            (<span>
                              {distributor.customer.source[0].description}
                            </span>)
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </MediaQuery>
            {/* mobile max wight 600 */}
            <MediaQuery maxWidth={600}>
              <Row
                type="flex"
                justify="space-between"
                align="top"
                className="product-info-mobile"
              >
                <Col span={24} style={{ marginBottom: "15px" }}>
                  <Row type="flex" justify="space-between" align="top" style={{ alignItems: "center" }}>
                    <Col span={6} style={{ alignItems: "center" }}>
                      <h3>
                        <img src={image} alt={item.material.description} width={80} style={{ height: 80, objectFit: 'contain' }} />
                      </h3>
                    </Col>
                    <Col span={18}>
                      <Row type="flex" style={{ flexFlow: "column" }}>
                        <h5 style={{ fontSize: "100%" }}>{item.material.material_number}</h5>
                        <h3 style={{ fontSize: "100%" }}>{item.material.description}</h3>
                      </Row>
                      <Divider style={{ margin: "10px 0" }} />
                      <Row type="flex" justify="space-between" align="top">
                        <Col span={12} type="flex" justify="center">
                          <label>
                            <Row type="flex" justify="center">
                              AMOUNT
                              <label></label>
                            </Row>
                            <Row type="flex" justify="center">
                              (PHP VAT-IN)
                            </Row>
                          </label>
                          <Row type="flex" justify="center">
                            <span>
                              {numFormat("dot/comma", item.material.price + (item.material.price * 0.12)) || "0.00"}
                            </span>
                          </Row>
                        </Col>
                        <Col span={12} type="flex" justify="center">
                          <label>
                            <Row type="flex" justify="center">
                              AMOUNT
                              <label></label>
                            </Row>
                            <Row type="flex" justify="center">
                              (PHP VAT-IN)
                            </Row>
                          </label>
                          <Row type="flex" justify="center">
                            <span>
                              {numFormat("dot/comma", item.material.price) || "0.00"}
                            </span>
                          </Row>
                        </Col>
                      </Row>

                    </Col>
                  </Row>
                </Col>
                <Row style={{ display: "flex", width: "100%", margin: "15px" }}>
                  <Col span={12}>
                    <Row>
                      <label>ORDERED AMOUNT</label>
                    </Row>
                    <Row>
                      <label>(PHP VAT-IN)</label>
                    </Row>
                  </Col>
                  <Col span={12} style={{ display: "flex", justifyContent: "center" }}>
                    <span>
                      {numFormat("dot/comma", (item.material.price + (item.material.price * 0.12)) *
                        inList.map((value) => {
                          return value.quantity || 0;
                        })
                      ) || "0.00"}
                    </span>
                  </Col>
                </Row>
                <Row style={{ display: "flex", width: "100%", margin: "5px 15px 5px 15px" }}>
                  <Col span={12}>
                    <label>SOURCE</label>
                  </Col>
                  <Col span={12} className="source-container" style={{ display: "flex" }}>
                    {distributor.customer.source.length > 1 ?
                      (<Field
                        name={`${field}.source`}
                        placeholder="Select Source"
                        component={renderSelectField}
                        onChange={(e) => handleSelectSource(e, item.material.material_number)}
                        defaultValue={1}
                        className="source-input"
                      >
                        {
                          distributor.customer.source.map((source) => {
                            return (<option value={source.id} className="select">
                              {source.description}
                            </option>)
                          })
                        }
                      </Field>) :

                      (<span>
                        {distributor.customer.source[0].description}
                      </span>)
                    }
                  </Col>
                </Row>
                {(customerTag && customerTag.includes('mto')) && <Row style={{ display: "flex", width: "100%", margin: "5px 15px 5px 15px" }}>
                  <Col span={12}>
                    <label>Availability Date</label>
                  </Col>
                  <Col span={12} style={{ display: "flex" }}>
                    <Field component={renderDatePicker2}
                      name={`${field}.availability_date`}
                      disabledDate={disabledDate}
                      formValues={_.get(formValues, field)}
                      defaultValue={availabilityDate()}
                      value={availabilityDate()}
                      disabled={(_.get(formValues, field).quantity > 0 ? false : true) || item.tagging === "regular"}
                    />
                  </Col>
                </Row>}

                <Row style={{ display: "flex", width: "100%", margin: "5px 15px 5px 15px" }}>
                  <Col span={12}>
                    <label>Ordered Quantity</label>
                  </Col>
                  <Col span={12} style={{ display: "flex" }}>
                    <Field
                      className="quantity-input"
                      id={`${id}_${item.material.material_code}`}
                      type="number"
                      name={`${field}.quantity`}
                      min={0}
                      component={FormField}
                      onChange={handleQty}
                      disabled={
                        !myPermissions.some(
                          (r) => r.name === "distributor.orders.create"
                        )
                      }
                    />
                  </Col>
                </Row>
              </Row>
            </MediaQuery>
          </List.Item >
        );
      }}
      header={
        < MediaQuery minWidth={1224} >
          <Row gutter={24} >
            <Col span={customerTag && customerTag.includes('mto') ? 8 : 11}>
              <h3>Product</h3>
            </Col>
            <Col span={4} align="right">
              <Row>
                <Col span={24} align="center"><h3>Amount  (PHP/UNIT)</h3></Col></Row>
              <Row>
                <Col span={12} align="center">
                  <h3>VAT-IN</h3>
                </Col>
                <Col span={12} align="center">
                  <h3>VAT-EX</h3>
                </Col>
              </Row>
            </Col>
            <Col span={3} align="right">
              <Row>
                <Col span={24} align="right"><h3>ORDERED AMOUNT</h3></Col></Row>
              <Row>
                <Col span={24} align="right">
                  <h3>(PHP VAT-IN)</h3>
                </Col>
              </Row>
            </Col>
            <Col span={3} align="center">
              <h3>
                Source
              </h3>
            </Col>
            {(customerTag && customerTag.includes('mto')) && <Col span={3} align="center">
              <h3>
                AVAILABILITY DATE
              </h3>
            </Col>}
            <Col span={3} align="center">
              <h3>
                ORDERED QUANTITY
              </h3>
            </Col>
          </Row>
        </MediaQuery >
      }
      footer={
        < Row gutter={24} >
          < MediaQuery minWidth={1224} >
            <Col className="order-totals" span={24}>
              <Row type="flex" align="middle">
                <Col span={14}>
                  <label style={{ color: 'white' }}>TOTAL AMOUNT</label>
                </Col>
                <Col span={3} align="right">
                  <span>&nbsp;{numFormat("dot/comma", totals.price + (totals.price * 0.12)) || "0.00"}</span>
                </Col>
                <Col span={7} align="right" style={{ paddingRight: '105px' }}>
                  <span>&nbsp;{totals.quantity}</span>
                </Col>
              </Row>
            </Col>
          </MediaQuery>
          <MediaQuery maxWidth={1224}>
            <Row
              type="flex"
              justify="center"
              align="middle"
              className="fixed-b"
            >
              <Col span={24}>
                <Row style={{ display: "flex", justifyContent: "center" }}>
                  <Col span={12} style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                    <Row>
                      <label>
                        TOTAL
                        AMOUNT
                      </label>
                    </Row>
                    <Row>
                      <span>{numFormat("dot/comma", totals.price + (totals.price * 0.12)) || "0.00"}</span>
                    </Row>
                  </Col>
                  <Col span={12} style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                    <Row>
                      <label>
                        TOTAL
                        QUANTITY
                      </label>
                    </Row>
                    <Row>
                      <span>{totals.quantity}</span>
                    </Row>
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="center"
                  align="middle"
                  className="clear-savedraft-submit"
                >
                  <Col xs={24} sm={8} md={8} lg={8} align="center">
                    <Button
                      htmlType="reset"
                      onClick={resetForm}
                      disabled={
                        !totals.quantity ||
                        !myPermissions.some(
                          (r) => r.name === "distributor.orders.cancel"
                        )
                      }
                      className="btn-small btn-default"
                    >
                      Clear
                    </Button>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} align="center">
                    <Button
                      htmlType="button"
                      onClick={handleSaveAsDraft}
                      disabled={!totals.quantity || isDraftLoading}
                      className="btn-small btn-secondary"
                      loading={isDraftLoading}

                    >
                      Save As Draft
                    </Button>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} align="center">
                    <Button
                      htmlType="submit"
                      disabled={!totals.quantity}
                      className="btn-small btn-primary"
                    >
                      Submit
                    </Button>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} align="center">
                    <Button
                      type="primary"
                      onClick={() => onAttachmentModalShow(true)}
                      className="btn-small btn-primary"
                    >
                      Attachment
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </MediaQuery>
          <MediaQuery minWidth={1224}>
            <Col className="actions" style={{ display: "flex", justifyContent: "center" }} span={24}>
              <Button
                type="default"
                htmlType="reset"
                onClick={resetForm}
                disabled={
                  !totals.quantity ||
                  !myPermissions.some(
                    (r) => r.name === "distributor.orders.cancel"
                  )
                }
                className="btn-main btn-default"
              >
                Clear
              </Button>
              {myPermissions.some(
                (r) => r.name === "distributor.orders.create"
              ) ? (
                <Button
                  htmlType="button"
                  onClick={handleSaveAsDraft}
                  disabled={!totals.quantity || isDraftLoading}
                  className="btn-main btn-secondary"
                  loading={isDraftLoading}
                >
                  Save As Draft
                </Button>
              ) : (
                ""
              )}
              {myPermissions.some(
                (r) => r.name === "distributor.orders.create"
              ) ? (
                <div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!totals.quantity || productOrderLoading}
                    className="btn-main btn-primary"
                  >
                    Submit
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => onAttachmentModalShow(true)}
                    className="btn-main btn-primary"
                  >
                    Attachment
                  </Button>
                </div>
              ) : (
                ""
              )}
            </Col>
          </MediaQuery>
        </Row >
      }
    />
  );
};

class Order extends Component {
  lunrized;
  constructor() {
    super();
    this.state = {
      isOrderSubmitLoading: false,
      isDraftLoading: false,
      isOrderDeliveryModalShow: false,
      isCutOffNotifShow: false,
      isCsvUploadModalShow: false,
      isOrderProductModalShow: false,
      isOrderFormLoading: false,
      activeRate: null,
      showFeedbackModal: false,
      showQuestion: false,
      showThankYouModal: false,
      isPostingFeedback: false,
      sapOrderSummaryModalVisible: false,
      isFetchingPriceList: false,
      isInitializing: true,
      productOrderListData: [],
      startDate: null,
      productDataForModal: {
        data: [],
      },
      distributorInputData: {},
      filters: {},
      searchValue: "",
      newData: [],
      items: [],
      selectedTerm: "",
      orderSummary: {},
      sapOrderSummary: [],
      terms: [
        {
          label: 'Credit Term',
          value: 'ZFDO'
        },
        {
          label: 'Cash On Delivery',
          value: 'ZFDL'
        },
        {
          label: 'Cash In Advance',
          value: 'ZFCI'
        }
      ],
      formInitialValues: {},
      defaultDeliveryDate: "",
      isAttachmentModalVisible: false,
      fileList: []
    };
    this.onContactDetailsFormSubmit = this.onContactDetailsFormSubmit.bind(
      this
    );
    this.handleChangeDate = this.handleChangeDate.bind(this);
  }
  handleDeliveryDateChange = (date) => {
    this.setState({ selectedDeliveryDate: date });
  }
  resetFieldsAsDraft = () => {
    const {
      priceList: { pricelistData },
      dispatch,
    } = this.props;
    let i;
    for (i = 0; i < pricelistData.length; i++) {
      dispatch(change("productOrderForm", `orders[${i}].quantity`, 0));
    }
  };

  isOrderDraft(priceList, drafts, draftId) {
    _.each(priceList.data, (product, i) => {
      _.each(drafts.data, (draft) => {
        if (draft.id == draftId) {
          _.each(draft.draft_order_material, (draftProduct) => {
            if (
              product.material_variant_id == draftProduct.material_variant_id
            ) {
              priceList.data[i].source = draftProduct.source
              return (priceList.data[i].quantity = draftProduct.quantity);
            }
          });
        }
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.customerPricelistData !== nextProps.customerPricelistData) {
      this.setState({
        prices: nextProps.customerPricelistData.resource
      })
    }
    if (this.props !== nextProps) {
      let searchParams = new URLSearchParams(this.props.location.search);
      if (searchParams.has("draftId")) {
      }

      this.isOrderDraft(
        nextProps.priceList,
        nextProps.drafts,
        searchParams.get("draftId")
      );
    }

    if (this.props.distributor !== nextProps.distributor) {
      const { distributor } = nextProps;
      const etdMonth =
        distributor &&
        distributor.distributor &&
        distributor.distributor.etd_month;
      this.setState({
        startDate: moment().add(parseInt(etdMonth), "M").toDate(),
      });
    }
  }

  orderCutOffWarning() {
    const format = 'HH:mm:ss';
    const beforeTime = moment('16:00:00', format);
    const afterTime = moment('23:59:59', format);

    if (!moment().isBetween(beforeTime, afterTime)) return null;

    this.setState({
      isCutOffNotifShow: true,
    });

    const _self = this;

    Modal.warning({
      title: "It's past the order processing cut-off",
      content: "You may proceed with your order; however, this will be processed the next working day.",
      onOk() {
        _self.setState({
          isCutOffNotifShow: false
        })
      },
    });
  }

  async componentDidMount() {
    this.orderCutOffWarning();
    this.setState({ isInitializing: true });
    await this.props.createCustomerMaterial();
    await this.props.fetchPermissions();
    let searchParams = new URLSearchParams(this.props.location.search);
    this.props.fetchSoldTo();
    this.props.fetchStorageLocation();
    await this.props.fetchDistributor().then((response) => {
      this.setState({ selectedTerm: response.response?.data?.customer?.payment_term })
    });
    await this.props.fetchProducts();
    await this.props.fetchDrafts();
    if (searchParams.get("draftId")) {
      await this.props.fetchDraftById(searchParams.get("draftId"));
    }
    this.props.fetchPoRefNo();
    this.setState({ isInitializing: false, isOrderDeliveryModalShow: true })
    window.onbeforeunload = () => {
      return "Are you sure?";
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.formValues !== prevProps.formValues) {
      let orderedMaterials = _.filter(this.props.formValues.orders, (material) => parseInt(material.quantity) > 0);
      if (orderedMaterials.length <= 0) {
        return false;
      }
      this.setState({
        defaultDeliveryDate: moment(Math.max(...orderedMaterials.map(value => moment(value.availability_date)))).format("YYYY-MM-DD")
      })
    }

    if (prevState.searchValue !== this.state.searchValue) {
      const newData = [];
      _.forEach(this.props.priceList.data, (materialDetails) => {
        const materialNumber = materialDetails.material.material_number
        const materialDescription = materialDetails.material.description.toLowerCase()
        if (_.includes(materialNumber, this.state.searchValue)
          || _.includes(materialDescription, this.state.searchValue.toLocaleLowerCase())) {
          newData.push(materialDetails);
        }
      })
      this.setState({
        newData,
      });
    }
  }

  handleDeliveryDetailsSubmit = async (data) => {
    this.setState({
      isFetchingPriceList: true,
    })
    let orderSummary = {
      customer_id: String(this.props.distributor.customer.customer_code),
      sold_to: _.find(this.props.soldTo, { customer_partner_code: data.customer_sold_to }),
      sold_to_partner_code: data.customer_sold_to,
      ship_to: _.find(this.props.shipTo, { partner_code: data.customer_ship_to }),
      ship_to_partner_code: data.customer_ship_to,
      delivery_details: {
        estimated_delivery_date: moment(data.estimate_time_of_delivery).format('YYYY-MM-DD'),
        type: data.order_type,
      },
      payment_term: this.state.selectedTerm,
      special_instruction: data.special_instruction
    };
    const sold_to = orderSummary.sold_to.customer?.customer_code || orderSummary.sold_to.customer_code;
    const ship_to = orderSummary.ship_to.ship_to?.customer_code || orderSummary.ship_to.customer_code;

    await this.props.fetchProductPriceList({ sold_to: sold_to, ship_to: ship_to }).then(() => {
      const { priceList } = this.props;
      this.setState({ items: priceList.data });
    });
    await this.props.fetchCustomerPriceList(`${this.props.distributor.customer.customer_code}-${data.customer_sold_to}-${data.customer_ship_to}-${this.props.distributor.customer.source[0].shipping_point}`);

    this.setState({ isOrderDeliveryModalShow: false, orderSummary, isFetchingPriceList: false });
  };

  onOrderFormSubmit = (value) => {
    const customerTag = this.props.distributor.customer.tagging || [];
    let orderedMaterials = _.filter(this.props.formValues.orders, (material) => parseInt(material.quantity) > 0);

    this.setState({
      orderSummary: {
        ...this.state.orderSummary,
        items: orderedMaterials,
        delivery_details: {
          estimated_delivery_date: customerTag.includes("mto") ? this.state.defaultDeliveryDate : this.state.orderSummary.delivery_details.estimated_delivery_date,
          type: this.state.orderSummary.delivery_details.type,
        }
      }
    }, () => {
      if ((this.state.orderSummary['sold_to']['customer']['payment_term'] === 'ZFDL' && ['ZFDO'].includes(value.term_input)) ||
        (this.state.orderSummary['sold_to']['customer']['payment_term'] === 'ZFCI' && ['ZFDO', 'ZFDL'].includes(value.term_input))
      ) {
        message.error('Please change the PAYMENT TERM in Order Form', 5);
      } else {
        this.setState({
          isOrderProductModalShow: true,
        });
      }
    })
  };

  submitToSAP = async () => {
    this.setState({ isOrderFormLoading: true });
    const orderedMaterials = _.filter(this.props.formValues.orders, (material) => parseInt(material.quantity) > 0);
    const orderedMaterialsTotalQuantity = _.sumBy(orderedMaterials, 'quantity');
    let total = _.sumBy(orderedMaterials, (itemDetails) => itemDetails.material.price * parseInt(itemDetails.quantity))
    const payload = {
      customer_id: String(this.props.distributor.customer.customer_code),
      sold_to: this.state.orderSummary.sold_to_partner_code,
      ship_to: this.state.orderSummary.ship_to_partner_code,
      total_quantity: orderedMaterialsTotalQuantity,
      delivery_details: {
        estimated_delivery_date: this.state.orderSummary.delivery_details.estimated_delivery_date,
        type: this.state.orderSummary.delivery_details.type
      },
      payment_term: this.state.selectedTerm,
      special_instruction: this.state.orderSummary.special_instruction,
      items: _.map(orderedMaterials, (materialDetails) => {
        return {
          material_code: materialDetails.material.material_number,
          quantity: materialDetails.quantity,
          unitOfMeasure: materialDetails.material.sales_unit,
          storage_location: materialDetails.source,
          unit_price: materialDetails.material.price,
          availability_date: materialDetails.availability_date,
          tagging: materialDetails.tagging
        }
      }),
      email_items: _.map(orderedMaterials, (materialDetails) => {
        return {
          description: materialDetails.material.description,
          material_code: materialDetails.material.material_number,
          quantity: materialDetails.quantity,
          unitOfMeasure: materialDetails.material.sales_unit,
          storage_location: materialDetails.source,
          unit_price: materialDetails.material.price
        }
      }),
      deliver_to: this.state.orderSummary.ship_to,
      order_total: total,
    };

    this.props.submitOrder(payload).then((res) => {
      this.props.fetchOrderHistory(res.data.data.order_transaction_id).then((history) => {
        message.warning("We are processing your order/s Please wait for the system to confirm.")

        // Validation
        let isAllSubmitted;
        _.forEach(history.data, (historyData) => {
          // If all SOs status is not confirmed or approved, start retrying

          // send to API for uploading of file
          this.props.uploadOrderAttachment(historyData.id, this.state.fileList);

          if (!['Confirmed', 'Approved', 'Blocked', 'For Review'].includes(historyData.status) || historyData.order_material.length < 1) {
            isAllSubmitted = false;
            return false;
          }
          isAllSubmitted = true;
        })
        // If all SOs status is not confirmed or approved, start retrying
        if (!isAllSubmitted) {
          const count = payload.items.length
          // fetch every 3 seconds until order is approved
          const maxRetries = count < 4 ? 10 : 3 * count;
          let retries = 1;
          const refresh = setInterval(() => {
            // if max retries is reached, prompt error and stop retrying
            if (retries === parseInt(maxRetries / 2, 10)) {
              message.warning("We are processing your order/s Please wait for the system to confirm.")
            }
            else if (retries >= maxRetries) {
              Modal.error({
                title: "There seems to be an issue with order confirmation.  You may check your order details via Order Status",
                content: "",
                icon: <img src={error} alt="error" />,
              });
              this.setState({
                isOrderFormLoading: false
              });
              clearInterval(refresh);
              return false;
            }
            retries++;
            let isAllSubmittedOnRefresh, timeout;
            this.props.fetchOrderHistory(res.data.data.order_transaction_id).then((refreshedHistory) => {
              _.forEach(refreshedHistory.data, (historyData) => {
                // If all SOs status is not confirmed or approved, start retrying
                if (!['Confirmed', 'Approved', 'Blocked', 'For Review'].includes(historyData.status) || historyData.order_material.length < 1) {
                  isAllSubmittedOnRefresh = false;
                  return false;
                }
                isAllSubmittedOnRefresh = true;
              })

              if (isAllSubmittedOnRefresh) {
                clearInterval(refresh);
                clearTimeout(timeout);
                this.setState({
                  isOrderFormLoading: false,
                  sapOrderSummaryModalVisible: true,
                  isOrderDeliveryModalShow: false,
                  isOrderProductModalShow: false,
                  sapOrderSummary: _.map(refreshedHistory.data, (details) => {
                    return {
                      ...details,
                      term_label: _.find(this.state.terms, { 'value': this.state.orderSummary.payment_term }).label
                    }
                  })
                });
              }
            })
          }, 3000)


        } else {
          this.setState({
            isOrderFormLoading: false,
            sapOrderSummaryModalVisible: true,
            isOrderDeliveryModalShow: false,
            isOrderProductModalShow: false,
            sapOrderSummary: _.map(history.data, (details) => {
              return {
                ...details,
                term_label: _.find(this.state.terms, { 'value': this.state.orderSummary.payment_term }).label
              }
            })
          });
        }
      })
    }).catch((err) => {
      this.setState({ isOrderFormLoading: false });
      Modal.error({
        title: "There seems to be an issue with order confirmation.  You may check your order details via Order Status",
        content: "",
        icon: <img src={error} alt="error" />,
      });
    })
  };

  onContactDetailsFormSubmit(value) {
    const { priceList } = this.props;
    let fromPricelist = priceList.pricelistData ? priceList.pricelistData : [];
    let fromOrders =
      this.state.productOrderListData.orders !== undefined
        ? this.state.productOrderListData.orders
        : [];

    let productOrderDataModal = [];
    let qty = 0,
      cbm = 0,
      selling_price = 0;

    fromOrders.forEach((fromOrder, i) => {
      fromPricelist.forEach((fromPricelist, j) => {
        if (fromOrder.product_variant_id === fromPricelist.product_variant_id) {
          qty = qty + Number(fromOrder.quantity);
          cbm =
            cbm +
            Number(fromOrder.quantity) * Number(fromPricelist.variant.cbm);
          selling_price =
            selling_price + fromOrder.quantity * fromOrder.selling_price;
          productOrderDataModal.push({ ...fromPricelist, ...fromOrder });
        }
      });
    });

    const {
      distributor_address_id,
      email_address,
      distributor_contact_id,
      port_of_destination,
      po_ref_no,
      special_instructions,
      sales_ref_number,
      volume,
    } = value;

    this.setState({
      isOrderProductModalShow: !this.state.isOrderProductModalShow,
      isOrderDeliveryModalShow: !this.state.isOrderDeliveryModalShow,
      productDataForModal: {
        data: productOrderDataModal,
        totalQty: qty,
        totalCbm: cbm,
        totalSellingPrice: selling_price,
      },

      distributorInputData: {
        volume,
        distributor_address_id,
        email_address,
        distributor_contact_id,
        port_of_destination,
        po_ref_no,
        special_instructions,
        sales_ref_number,
      },
    });
  }

  resetOrderForm() {
    let searchParams = new URLSearchParams(this.props.location.search);
    if (searchParams.has("draftId")) this.resetFieldsAsDraft();
    else {
      this.props.dispatch(reset("productOrderForm"))
      this.props.dispatch(reset("delivery_details_form"))
    };

  }

  onCloseDeliveryFormModal = () => {
    // this.props.dispatch(reset("delivery_details_form"));
    this.setState({ isOrderDeliveryModalShow: false });
  };

  onCloseSapOrderSummaryModal = () => {
    this.setState({ sapOrderSummaryModalVisible: false });
  };
  showFeedbackModal = () => {
    this.setState({ showFeedbackModal: true, sapOrderSummaryModalVisible: false });
  };

  displayDistributorBasedOnType(type) {
    const { distributor } = this.props;
    let emailArray = [];
    distributor &&
      distributor.distributor.contact.map((data) => {
        if (data.type === type) emailArray.push(data);
      });
    return emailArray;
  }

  displayDistributorBasedOnId(id, type, column, key) {
    id = parseInt(id);
    const { distributor } = this.props;
    let returnData;
    distributor &&
      distributor.distributor[column].map((data) => {
        if (data.id === id) returnData = data[key];
      });
    return returnData;
  }

  handleChangeDate(date) {
    this.setState({
      startDate: date,
    });
  }

  handleSaveAsDraft(value) {
    const { distributor, storageLocation } = this.props;
    const { selectedTerm } = this.state
    this.setState({ isDraftLoading: true })
    let materialDrafts = [];
    const searchParams = new URLSearchParams(this.props.location.search);
    // get materials with quantity
    const materials = _.filter(
      value.orders,
      (order) => parseInt(order.quantity) > 0
    )

    materials.forEach((value, key) => {
      const source = _.find(storageLocation, { id: parseInt(value.source, 10) });
      materialDrafts.push({
        'material_variant_id': value.material.id,
        'quantity': value.quantity,
        'source': source.description
      });
    })

    this.props
      .saveAsDraft(
        {
          'customer_id': distributor.customer.id,
          'sold_to_id': this.state.orderSummary.sold_to.customer_id,
          'ship_to_id': this.state.orderSummary.ship_to.ship_to.id,
          'type': this.state.orderSummary.delivery_details.type,
          'estimated_delivery_date': this.state.orderSummary.delivery_details.estimated_delivery_date,
          'special_instruction': this.state.orderSummary.special_instruction || null,
          'payment_term': selectedTerm,
          'items': materialDrafts
        },
        searchParams.get("draftId")
      )
      .then((response) => {
        this.setState({ isDraftLoading: false })
        if (response.error) {
          Modal.error({
            title: "Unable to save as draft your order. Please try again.",
            content: "",
            icon: <img src={error} alt="error" />,
          });
        } else {
          Modal.success({
            title: (
              <span>Draft!</span>
            ),
            content:
              "You shall now be redirected to the draft order page.",
            icon: <div style={{ paddingLeft: 15 }}><img src={success} alt="success" style={{ height: 25, width: 25 }} /></div>,
          });
          this.props.history.push("/drafts");
        }
      });
  }

  handleClickRate = (rate) => {
    this.setState({ activeRate: rate, showQuestion: true });
  };

  handleFeedbackSubmit = () => {
    const { activeRate, comments } = this.state;
    const { distributor, postFeedback } = this.props;

    if (!activeRate) {
      message.error("Please input your feedback");
      return;
    }
    if (!comments) {
      message.error("Please provide your comments and suggestions");
      return;
    }

    _.map(this.state.sapOrderSummary, (orderSummary) => {
      const data = {
        po_date: moment().format("YYYY-MM-DD"),
        distributor_username: distributor.username,
        comments: comments,
        feedback_score: activeRate,
        feedback_type: "order placement",
        distributor_order_id: orderSummary.id
      };

      this.setState({ isPostingFeedback: true });

      postFeedback(data).then((response) => {
        if (!response.error) {
          this.setState({
            activeRate: null,
            showThankYouModal: true,
            showFeedbackModal: false,
            isPostingFeedback: false,
            comments: "",
            sapOrderSummaryModalVisible: false,
          });
        } else {
          this.setState({
            activeRate: null,
            showFeedbackModal: false,
            isPostingFeedback: false,
            comments: "",
          });
        }
      });
    })

  };

  handleFeedbackSuccess = () => {
    const id = this.state.sapOrderSummary[0].id;
    this.props.history.push(`purchase-status?id=${id}`);
  }

  handleProductSearch = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  searchResultIndicator = () => {
    const { searchValue, newData } = this.state;
    if (searchValue) {
      if (newData.length == 1) {
        return (
          <div className="search-result-indicator">{`${newData.length}  Product Found`}</div>
        );
      } else if (newData.length >= 1) {
        return (
          <div className="search-result-indicator">{`${newData.length}  Products Found`}</div>
        );
      } else {
        return "";
      }
    }
  };

  setAttachmentVisbility = (isShown) => {
    this.setState({
      isAttachmentModalVisible: isShown
    })
  }

  setAttachmentFiles = (fileList) => {
    this.setAttachmentVisbility(false)
    this.setState({
      fileList: fileList
    })
  }

  render() {
    let {
      myPermissions,
      myPermissionsIsFetching,
      formValues,
      handleSubmit,
      distributor,
      soldTo,
      productOrderForm,
      storageLocation,
      fetchShipTo
    } = this.props;
    const {
      activeRate,
      showThankYouModal,
      showFeedbackModal,
      isPostingFeedback,
      comments,
      isInitializing,
      orderSummary,
      isAttachmentModalVisible
    } = this.state;

    distributor = (distributor && distributor.customer) || "";
    const meta = {
      title: "Order Now - URC Flour Division",
      description:
        "URC Flour Division seeks global awareness for its brands, enter new territories and explore untapped business opportunities. Learn more about us today!",
      canonical: window.location.href,
      meta: {
        charset: "utf-8",
        name: {
          keywords: "Order Now",
        },
      },
    };

    if (myPermissionsIsFetching) {
      return (
        <DocumentMeta {...meta}>
          <Layout>
            <Spin spinning={myPermissionsIsFetching}>
              <Skeleton active />
            </Spin>
          </Layout>
        </DocumentMeta>
      );
    }
    function getSources() {
      let sources = [];
      _.forEach(formValues.orders, (material) => {
        const mappedMaterialSource = _.find(storageLocation, { id: parseInt(material.source, 10) })
        if (!_.includes(sources, mappedMaterialSource.description) && material.source !== null && material.quantity > 0) {
          sources.push(mappedMaterialSource.description);
        }
      });
      return sources.join();
    }
    const handleTermSelect = (e) => {
      this.setState({ selectedTerm: e.target.value })
    }
    function getTotalPrice(items) {
      let total = _.sumBy(items, (itemDetails) => itemDetails.material.price * parseInt(itemDetails.quantity))

      return total + (total * 0.12);
    }
    const handleSourceSelectRecomputation = (index, item, items) => {
      items[index].material.price = item.material.price;
      this.setState({ items: items });
    }

    const handlePriceCallback = async (storageLocationCode, materialCode) => {
      await this.props.fetchCustomerMaterialPriceList(`${distributor.customer_code}-${orderSummary.sold_to.customer?.customer_code}-${orderSummary.ship_to.ship_to?.customer_code}-${storageLocationCode}`, materialCode).then(() => {
        let { prices: customerPrices } = this.state;
        const { customerMaterialPricelistData } = this.props;
        const materialPrice = customerMaterialPricelistData.resource;
        const priceIndex = _.findIndex(customerPrices, (customerMaterialPrice) => parseInt(customerMaterialPrice.material_number, 10) === parseInt(materialPrice.material_number, 10));

        if (priceIndex > -1) {
          customerPrices[priceIndex] = materialPrice;
          this.setState({
            prices: customerPrices
          });
        }
      });
    }

    return (
      <DocumentMeta {...meta}>
        <Layout>
          {myPermissions.some((r) => r.name === "distributor.orders.view" && distributor.status === true && distributor.customer_group) ? (
            <Layout.Content className="product-order-content">
              <Row >
                <Col className="product-order-form-page" span={24}>
                  <Card bordered={false}>
                    <Row
                      type="flex"
                      justify="space-between"
                      align="middle"
                      className="header"
                      style={{ gap: "10px" }}
                    >
                      <Col>
                        <h2>Order Form</h2>
                      </Col>
                      <Col style={{ display: 'flex', gap: '12px', alignItems: 'flex-end', flexWrap: "wrap" }}>
                        <Link to={"/order-status"}>
                          <Button
                            className="btn-small btn-primary"
                            disabled={this.state.isOrderFormLoading}
                          >
                            VIEW RECENT ORDERS
                          </Button>
                        </Link>
                        <Button
                          className="btn-small btn-primary"
                          disabled={this.state.isOrderFormLoading}
                          onClick={() => {
                            this.setState({
                              isOrderDeliveryModalShow: true,
                            });
                          }}
                        >
                          EDIT DELIVERY DETAILS
                        </Button>
                      </Col>
                    </Row>
                    <MediaQuery minWidth={1224}>
                      <div className="product-order-query">
                        <Form
                          className="product-order-query-form"
                          layout="horizontal"
                        >
                          <Row gutter={16}>
                            <Col md={16} className="search-container">
                              <Form.Item>
                                <Input.Search
                                  prefix={<Icon type="search" />}
                                  placeholder="Search"
                                  value={this.state.searchValue}
                                  onChange={this.handleProductSearch}
                                  size="large"
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              md={8}

                            >
                              <div style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: 'center',
                                width: '100%',
                                height: '70%',
                                gap: '12px'
                              }}>
                                <h3>PAYMENT TERM:</h3>
                                {
                                  distributor.payment_term === 'ZFDO' ?
                                    <Field
                                      name={`term_input`}
                                      placeholder="Select Term"
                                      className="term-input"
                                      component={renderSelectField}
                                      defaultValue={distributor.payment_term || ""}
                                      onChange={handleTermSelect}
                                    >
                                      <option value="ZFCI" className="select">
                                        Cash In Advance
                                      </option>
                                      <option value="ZFDL" className="select">
                                        Cash On Delivery
                                      </option>
                                      <option value="ZFDO" className="select">
                                        Credit Term
                                      </option>
                                    </Field>
                                    : distributor.payment_term === 'ZFDL' ?
                                      <Field
                                        name={`term_input`}
                                        placeholder="Select Term"
                                        className="term-input"
                                        component={renderSelectField}
                                        defaultValue={distributor.payment_term || ""}
                                        onChange={handleTermSelect}
                                      >
                                        <option value="ZFCI" className="select">
                                          Cash In Advance
                                        </option>
                                        <option value="ZFDL" className="select">
                                          Cash On Delivery
                                        </option>
                                      </Field>
                                      : (<span>Cash In Advance</span>)
                                }
                              </div>

                            </Col>
                          </Row>
                        </Form>
                      </div>

                    </MediaQuery>
                    <MediaQuery maxWidth={1224}>
                      <div className="product-order-query">
                        <Form
                          className="product-order-query-form"
                          layout="horizontal"
                        >
                          <Row
                            type="flex"
                            justify="space-between"
                            align="middle"
                          >
                            <Col span={24} className="search-container">
                              <Form.Item>
                                <Input.Search
                                  type="text"
                                  placeholder="Search"
                                  prefix={<Icon type="search" />}
                                  value={this.state.searchValue}
                                  onChange={this.handleProductSearch}
                                  size="large"
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              span={24}

                            >
                              <div style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: 'center',
                                width: '100%',
                                height: '70%',
                                gap: '12px'
                              }}>
                                <h3>TERM:</h3>
                                {
                                  distributor.payment_term === 'ZFDO' ?
                                    <Field
                                      name={`term_input`}
                                      placeholder="Select Term"
                                      className="term-input"
                                      component={renderSelectField}
                                      defaultValue={distributor.payment_term || ""}
                                      onChange={handleTermSelect}
                                    >
                                      <option value="ZFCI" className="select">
                                        CIA
                                      </option>
                                      <option value="ZFDL" className="select">
                                        COD
                                      </option>
                                      <option value="ZFDO" className="select">
                                        CT
                                      </option>

                                    </Field>
                                    : distributor.payment_term === 'ZFDL' ?
                                      <Field
                                        name={`term_input`}
                                        placeholder="Select Term"
                                        className="term-input"
                                        component={renderSelectField}
                                        defaultValue={distributor.payment_term || ""}
                                        onChange={handleTermSelect}
                                      >
                                        <option value="ZFCI" className="select">
                                          CIA
                                        </option>
                                        <option value="ZFDL" className="select">
                                          COD
                                        </option>

                                      </Field>
                                      : (<span>{distributor.payment_term}</span>)
                                }
                              </div>

                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </MediaQuery>
                    <div className="product-order-result">
                      {this.searchResultIndicator()}
                      <Form
                        className="product-order-query-form"
                        onSubmit={handleSubmit(
                          this.onOrderFormSubmit.bind(this)
                        )}
                      >
                        <FieldArray
                          name="orders"
                          handleSaveAsDraft={handleSubmit(
                            this.handleSaveAsDraft.bind(this)
                          )}
                          submitForm={handleSubmit(
                            this.onOrderFormSubmit.bind(this)
                          )}
                          products={
                            this.state.searchValue
                              ? this.state.newData
                              : this.state.items
                          }
                          prices={this.state.prices}
                          customerPricelistFetching={this.props.customerPricelistFetching}
                          resetForm={this.resetOrderForm.bind(this)}
                          formValues={formValues}
                          isOrderSubmitLoading={this.state.isOrderSubmitLoading}
                          isDraftLoading={this.state.isDraftLoading}
                          productOrderLoading={this.props.productOrderLoading}
                          productsIsFetching={this.props.productsIsFetching}
                          priceListIsFetching={this.props.priceListIsFetching}
                          fetchProductById={this.props.fetchProductById}
                          myPermissions={myPermissions}
                          renderSelectField={renderSelectField}
                          distributor={this.props.distributor}
                          productOrderForm={productOrderForm}
                          isInitializing={isInitializing}
                          orderError={this.state.orderError}
                          errorMessage={this.state.errorMessage}
                          handleSourceSelectRecomputation={handleSourceSelectRecomputation}
                          handlePriceCallback={handlePriceCallback}
                          orderSummary={this.state.orderSummary}
                          orderState={this}
                          component={orderProductList}
                          onAttachmentModalShow={this.setAttachmentVisbility}
                        />
                      </Form>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Layout.Content>
          ) : (
            <Result
              status="warning"
              title="You have no permission to view this page."
            />
          )}

          <Modal
            className="feedback-modal"
            title={<span>We'd like to get your feedback</span>}
            visible={showFeedbackModal}
            onCancel={() => this.handleFeedbackSuccess()}
            footer={null}
            maskClosable={false}
            closable={true}
          >
            <h3>How was your overall order experience?</h3>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="feedback-rate"
            >
              {feedbackEmoji.sort((a, b) => b.rate - a.rate).map((value, index) => (
                <Col
                  key={index}
                  onClick={() => this.handleClickRate(value.rate)}
                  className={value.rate === activeRate ? "active" : ""}
                >
                  <img alt={value.rate} src={value.svg} />
                </Col>
              ))}
            </Row>
            <Form>
              <Row className="feedback-question">
                <Col span={24}>
                  <h3>Any comments/suggestions?</h3>
                  <div className="form-label">
                    <Input
                      size="large"
                      onChange={(e) =>
                        this.setState({ comments: e.target.value })
                      }
                      value={comments}
                      className="inputStyle"
                    />
                  </div>
                </Col>
              </Row>
              <Row type="flex" justify="end" className="feedback-button">
                <Col>
                  <Button
                    htmlType="submit"
                    className="btn-small btn-primary"
                    style={{ width: "192px" }}
                    loading={isPostingFeedback}
                    onClick={() => this.handleFeedbackSubmit()}
                  >
                    {isPostingFeedback ? null : "Send Feedback Form"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal>

          <Modal
            className="feedback-modal"
            title={<span>Feedback Received!</span>}
            visible={showThankYouModal}
            footer={null}
            closable={false}
          >
            <h3><img src={success} alt="success" style={{ height: 20, width: 20, marginRight: '15px' }} /> Thank you for your feedback!</h3>
            <p>
              Rest assured that we are continuously enhancing your ordering
              experience with us.
            </p>
            <Row type="flex" justify="end" className="feedback-button">
              <Col span={24} align="right">
                <Button
                  style={{ margin: 0, minWidth: "80px" }}
                  className="btn-small btn-primary"
                  onClick={() => this.handleFeedbackSuccess()}
                >
                  Ok
                </Button>
              </Col>
            </Row>
          </Modal>
          {this.state.isOrderDeliveryModalShow && (
            <ModalDeliveryDetails
              sources={getSources()}
              distributor={distributor}
              formValidators={formValidators}
              renderSelectField={renderSelectField}
              renderInput={renderInput}
              renderDatepicker={renderDatepicker}
              displayDistributorBasedOnType={this.displayDistributorBasedOnType}
              isOrderDeliveryModalShow={this.state.isOrderDeliveryModalShow && !this.state.isCutOffNotifShow}
              onCloseDeliveryFormModal={this.onCloseDeliveryFormModal}
              isFetchingPriceList={this.state.isFetchingPriceList}
              onSubmit={this.handleDeliveryDetailsSubmit}
              defaultDeliveryDate={this.state.defaultDeliveryDate}
              soldTo={soldTo}
              loadShipTo={fetchShipTo}
              draft={this.props.drafts}
            />
          )}

          {this.state.isOrderProductModalShow && (
            <Modal
              className="order-product-confirm-modal"
              visible={this.state.isOrderProductModalShow}
              style={{ top: 20 }}
              onCancel={() => {
                this.setState({
                  isOrderProductModalShow: false,
                  isOrderDeliveryModalShow: false,
                });
              }}
              footer={null}
              closable={false}
              id="order_sent"
            >

              <Layout>
                <Layout.Content className="order-confirmation-header">
                  <Row className="header-content">
                    <Col align="left">
                      <h1>Order Summary</h1>
                    </Col>
                    <Col align="right">
                      <Button
                        className="btn-small btn-primary"
                        disabled={this.state.isOrderFormLoading}
                        onClick={() => {
                          this.setState({
                            isOrderDeliveryModalShow: true,
                            isOrderProductModalShow: false,
                          });
                        }}
                      >
                        EDIT DELIVERY DETAILS
                      </Button>
                    </Col>
                  </Row>
                </Layout.Content>
                <Layout.Content className="order-confirmation-content">
                  <Card style={{ padding: "1vh 1vw" }}>
                    <Layout.Content>
                      <Row>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}> <h3>Sold To</h3></Col>
                        <Col span={8}><h3>{this.state.orderSummary.sold_to?.customer?.name || this.state.orderSummary.sold_to?.name}</h3></Col>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}> <h3>Ship To</h3></Col>
                        <Col span={8}><h3>{this.state.orderSummary.ship_to?.ship_to?.name || this.state.orderSummary.ship_to?.name}</h3></Col>
                      </Row>
                      <Row>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}> <h3>Sold To Code</h3></Col>
                        <Col span={8}><h3>{this.state.orderSummary?.sold_to?.customer?.customer_code || this.state.orderSummary.sold_to?.customer_code}</h3></Col>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}> <h3>Ship To Code</h3></Col>
                        <Col span={8}><h3>{this.state.orderSummary?.ship_to?.ship_to?.customer_code || this.state.orderSummary.ship_to?.customer_code}</h3></Col>
                      </Row>
                      <Row>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}> <h3>Address</h3></Col>
                        <Col span={8}>
                          <h3>
                            {this.state.orderSummary.ship_to?.ship_to?.address || this.state.orderSummary.ship_to?.address},
                            {this.state.orderSummary.ship_to?.ship_to?.city || this.state.orderSummary.ship_to?.city},
                            {this.state.orderSummary.ship_to?.ship_to?.postal_code || this.state.orderSummary.ship_to?.address}
                          </h3>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}> <h3>Payment Term</h3></Col>
                        <Col span={8}> <h3>{_.find(this.state.terms, { 'value': this.state.selectedTerm }).label}</h3></Col>
                      </Row>
                      <Row>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}> <h3>Source</h3></Col>
                        <Col span={8}> <h3>{getSources()}</h3></Col>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}> <h3>Delivery Date</h3></Col>
                        <Col span={8}> <h3>
                          {moment(this.state.orderSummary.delivery_details.estimated_delivery_date).format('ll')} /
                          <span className="delivery-type"> {this.state.orderSummary.delivery_details.type}</span>
                        </h3></Col>
                      </Row>
                      <Row>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}> <h3>Special Instruction</h3></Col>
                        <Col xl={20} lg={20} md={20} sm={12} xs={12}> <h3>{this.state.orderSummary.special_instruction}</h3></Col>
                      </Row>
                    </Layout.Content>
                  </Card>
                  <Card >
                    <Layout.Content>
                      <List
                        bordered
                        itemLayout="horizontal"
                        dataSource={this.state.orderSummary.items}
                        className="order-summary-item-list"
                        renderItem={(field) => {
                          return (
                            <List.Item style={{ width: '100%' }}>
                              <Col span={24}>
                                <MediaQuery minWidth={600}>
                                  <Row
                                    gutter={24}
                                    type="flex"
                                    align="middle"
                                  >
                                    <Col span={8} className="product-information">
                                      <span className="product-id">
                                        <h4>{field.material.material_number}</h4>
                                        <h3>{field.material.description}</h3>
                                      </span>
                                    </Col>
                                    <Col span={3} align="center">
                                      {numFormat("dot/comma", field.material.price + (field.material.price * 0.12)) || "0.00"}
                                    </Col>
                                    <Col span={3} align="center">
                                      {numFormat("dot/comma", field.material.price) || "0.00"}
                                    </Col>
                                    <Col span={3} align="right">
                                      {field.availability_date}
                                    </Col>
                                    <Col span={3} align="right">
                                      {field.quantity}
                                    </Col>
                                    <Col span={4} align="right">
                                      {numFormat("dot/comma", ((field.material.price + (field.material.price * 0.12)) * parseInt(field.quantity))) || "0.00"}
                                    </Col>
                                  </Row>
                                </MediaQuery>
                                <MediaQuery maxWidth={599}>
                                  <Row
                                    gutter={24}
                                    type="flex"
                                    align="middle"
                                  >
                                    <Col span={12} className="product-information">
                                      <Row>
                                        <Col span={24}>
                                          <h3>Product</h3>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col span={24}>
                                          <h3>{field.material.material_number}</h3>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col span={24}>
                                          <h3>{field.material.description}</h3>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col span={12}>
                                      <Row>
                                        <Col span={24} align="left">
                                          <h3>VAT-IN</h3>
                                        </Col>
                                        <Col span={24} align="left">
                                          {numFormat("dot/comma", field.material.price + (field.material.price * 0.12)) || "0.00"}
                                        </Col>
                                        <Col span={24} align="left">
                                          <h3>VAT-EX</h3>
                                        </Col>
                                        <Col span={24} align="left">
                                          {numFormat("dot/comma", field.material.price) || "0.00"}
                                        </Col>
                                        <Col span={24} align="left">
                                          <h3>ORDERED QUANTITY</h3>
                                        </Col>
                                        <Col span={24} align="left">
                                          {field.quantity}
                                        </Col>
                                        <Col span={24} align="left">
                                          <h3>ORDERED AMOUNT</h3>
                                        </Col>
                                        <Col span={24} align="left">
                                          {numFormat("dot/comma", ((field.material.price + (field.material.price * 0.12)) * parseInt(field.quantity))) || "0.00"}
                                        </Col>
                                      </Row>
                                    </Col>

                                  </Row>
                                </MediaQuery>
                              </Col>
                            </List.Item>
                          );
                        }}
                        header={
                          // Hide headers on mobile view
                          <MediaQuery minWidth={600}>
                            <Row gutter={24} style={{ backgroundColor: '#F8F8F8' }}>
                              <Col span={8}>
                                <h4>PRODUCT</h4>
                              </Col>
                              <Col span={6} align="right">
                                <Row>
                                  <Col span={24} align="center"><h4>AMOUNT  (PHP/UNIT)</h4></Col>
                                </Row>
                                <Row>
                                  <Col span={12} align="center">
                                    <h4>VAT-IN</h4>
                                  </Col>
                                  <Col span={12} align="center">
                                    <h4>VAT-EX</h4>
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={3} align="right">
                                <h4>
                                  Availability Date
                                </h4>
                              </Col>
                              <Col span={3} align="right">
                                <h4>
                                  ORDERED QUANTITY
                                </h4>
                              </Col>
                              <Col span={4} align="right">
                                <h4>
                                  ORDERED AMOUNT <br>
                                  </br>(PHP VAT-IN)
                                </h4>
                              </Col>
                            </Row>
                          </MediaQuery>

                        }
                        footer={
                          <Row gutter={24}>
                            <MediaQuery minWidth={1224}>
                              <Row
                                type="flex"
                                justify="center"
                                align="middle"
                                className="fixed-b"
                              >
                                <Col span={24}>
                                  <Row>
                                    <Col span={6} offset={9} align="right">
                                      <span>
                                        TOTAL
                                      </span>
                                    </Col>
                                    <Col span={5} align="right">
                                      <span>{_.sumBy(this.state.orderSummary.items, (material => parseInt(material.quantity)))}</span>
                                    </Col>
                                    <Col span={4} align="right">
                                      <span>{numFormat('dot/comma', getTotalPrice(this.state.orderSummary.items))}</span>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </MediaQuery>
                            <MediaQuery maxWidth={1224}>
                              <Row style={{ display: "flex", justifyContent: "center" }}>
                                <Col span={12} style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                  <Row>
                                    <label>
                                      TOTAL
                                      AMOUNT
                                    </label>
                                  </Row>
                                  <Row>
                                    <span>{numFormat('dot/comma', getTotalPrice(this.state.orderSummary.items))}</span>
                                  </Row>
                                </Col>
                                <Col span={12} style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                  <Row>
                                    <label>
                                      TOTAL
                                      QUANTITY
                                    </label>
                                  </Row>
                                  <Row>
                                    <span>{_.sumBy(this.state.orderSummary.items, (material => parseInt(material.quantity)))}</span>
                                  </Row>
                                </Col>
                              </Row>
                            </MediaQuery>
                          </Row>

                        }
                      />
                      <Row
                        type="flex"
                        justify="space-between"
                        align="middle"
                        className="f-warning-submit"
                      >
                        <Col span={12}>
                          <img src={warningIcon} />
                          <h3>Are you confirming your order?</h3>
                        </Col>
                        <Col span={12} align="right">
                          <Button
                            className="btn-small btn-default"
                            disabled={this.state.isOrderFormLoading}
                            onClick={() => {
                              this.setState({
                                isOrderProductModalShow: false,
                                isOrderDeliveryModalShow: false,
                              });
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            id="Thank_you_for_your_order"
                            loading={this.state.isOrderFormLoading}
                            className="btn-small btn-primary"
                            onClick={() => this.submitToSAP()}
                          >
                            {this.state.isOrderFormLoading
                              ? null
                              : "Confirm Order"}
                          </Button>
                        </Col>
                      </Row>
                    </Layout.Content>
                  </Card>
                </Layout.Content>
              </Layout>
            </Modal>
          )}

          {this.state.sapOrderSummaryModalVisible && (
            <ModalSapOrderSummary
              isSapOrderSummaryModalShow={this.state.sapOrderSummaryModalVisible}
              onCloseSapOrderSummaryModal={this.onCloseSapOrderSummaryModal}
              sapOrderSummary={this.state.sapOrderSummary}
              proceedToFeedback={this.showFeedbackModal}
              distributor={this.props.distributor}
            />
          )}

          <ModalAttachment isVisible={isAttachmentModalVisible} excluded={["list"]} onClose={() => this.setAttachmentVisbility(false)} onSubmitCallback={this.setAttachmentFiles} />
        </Layout>
      </DocumentMeta>
    );
  }
}

const selector = formValueSelector("productOrderForm");

const mapStateToProps = (state) => {
  let {
    products,
    productCategories,
    productPriceList,
    drafts,
    distributor: { data: distributor },
    permissions,
    productOrder,
    soldTo,
    shipTo,
    storageLocation,
    customerPricelist
  } = state;
  return {
    productOrderForm: state.form.productOrderForm,
    products,
    distributor,
    soldTo: soldTo.data,
    shipTo: shipTo.data,
    storageLocation: storageLocation.data,
    myPermissions: permissions.data,
    myPermissionsIsFetching: permissions.fetching,
    productsIsFetching: products.fetching,
    priceListIsFetching: productPriceList.fetching,
    customerPricelistData: customerPricelist.data,
    customerMaterialPricelistData: customerPricelist.customerMaterialPrice,
    customerPricelistFetching: customerPricelist.fetching,
    drafts,
    categories: productCategories,
    priceList: productPriceList,
    formValues: { orders: selector(state, "orders") },
    productOrderLoading: productOrder.fetching,
    initialValues: {
      distributor_id: "",
      distributor_address_id: "",
      distributor_contact_id: "",
      status: 1,
      special_instructions: "",
      term_input: distributor?.customer.payment_term,
      orders: _.map(
        productPriceList.data,
        (product) => {
          const deliveryLeadTime = state.form.delivery_details_form.values.order_type === "delivery" ? 3 : 0;

          return {
            ...product,
            quantity: product.quantity ? product.quantity : 0,
            source: distributor.customer.source[0].id,
            availability_date: product.tagging === "mto" ? moment().add(product.availability_days, 'days').format('YYYY-MM-DD') : moment().add(deliveryLeadTime, 'days').format("YYYY-MM-DD")
          }
        }
      )
    } // NOTE: Change to default in customer details
  };
};

export default connect(mapStateToProps, {
  fetchProducts,
  fetchProductPriceList,
  fetchDistributor,
  fetchSoldTo,
  fetchShipTo,
  createProductOrder,
  fetchPermissions,
  fetchDraftById,
  saveAsDraft,
  fetchDrafts,
  fetchPoRefNo,
  postPoRefNo,
  postFeedback,
  isCustomerPoNoUnique,
  fetchProductById,
  submitOrder,
  fetchOrderHistory,
  createCustomerMaterial,
  fetchStorageLocation,
  fetchCustomerPriceList,
  fetchCustomerMaterialPriceList,
  uploadOrderAttachment
})(reduxForm({ form: "productOrderForm", enableReinitialize: true, keepDirtyOnReinitialize: true })(Order));
