import axios from 'axios';
import config from '../config';
import { headers } from '../utilities'

export const FETCH_SHIP_TO = 'FETCH_SHIP_TO';
export const FETCH_SHIP_TO_FULFILLED = 'FETCH_SHIP_TO_FULFILLED';
export const FETCH_SHIP_TO_REJECTED = 'FETCH_SHIP_TO_REJECTED';

export const fetchShipTo = (data) => dispatch => {
  if (Object.keys(data).length === 0) {
    dispatch({
      type: FETCH_SHIP_TO_FULFILLED,
      payload: []
    });
    
    return { error: false };
  }
  
  const searchParams = new URLSearchParams(data);

  dispatch({
    type: FETCH_SHIP_TO,
    payload: []
  });
  return axios.get(`${config.REACT_APP_API_BASE_URL}/customer-ship-to-list?${searchParams.toString()}`, { headers: headers() })
    .then(response => {
      dispatch({
        type: FETCH_SHIP_TO_FULFILLED,
        payload: response.data
      });
      return { error: false, response };
    })
    .catch(err => {
      dispatch({
        type: FETCH_SHIP_TO_REJECTED,
        payload: err
      })
      return { error: true, err };
    });
}