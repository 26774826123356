import axios from "axios";

import config from "../config";
import { headers } from "../utilities";

export const CREATE_PRODUCT_ORDER = "CREATE_PRODUCT_ORDER";
export const CREATE_PRODUCT_ORDER_FULFILLED = "CREATE_PRODUCT_ORDER_FULFILLED";
export const CREATE_PRODUCT_ORDER_REJECTED = "CREATE_PRODUCT_ORDER_REJECTED";

export const IS_CUSTOMER_PO_NO_UNIQUE = "IS_CUSTOMER_PO_NO_UNIQUE";
export const IS_CUSTOMER_PO_NO_UNIQUE_FULFILLED = "IS_CUSTOMER_PO_NO_UNIQUE_FULFILLED";
export const IS_CUSTOMER_PO_NO_UNIQUE_REJECTED = "IS_CUSTOMER_PO_NO_UNIQUE_REJECTED";

export const FETCH_PO_REF_NO = "FETCH_PO_REF_NO";
export const FETCH_PO_REF_NO_FULFILLED = "FETCH_PO_REF_NO_FULFILLED";
export const FETCH_PO_REF_NO_REJECTED = "FETCH_PO_REF_NO _REJECTED";

export const POST_PO_REF_NO = "POST_PO_REF_NO";
export const POST_PO_REF_NO_FULFILLED = "POST_PO_REF_NO_FULFILLED";
export const POST_PO_REF_NO_REJECTED = "POST_PO_REF_NO _REJECTED";

export const createProductOrder = data => dispatch => {
  dispatch({
    type: CREATE_PRODUCT_ORDER,
    payload: {}
  });
  return axios
    .post(`${config.REACT_APP_API_BASE_URL}/order`, data, {
      headers: headers()
    })
    .then(response => {
      dispatch({
        type: CREATE_PRODUCT_ORDER_FULFILLED,
        payload: response.data
      });
      return { error: false, response };
    })
    .catch(err => {
      dispatch({
        type: CREATE_PRODUCT_ORDER_REJECTED,
        payload: err
      });
      return { error: true, err };
    });
};

export const isCustomerPoNoUnique = data => dispatch => {
  dispatch({
    type: IS_CUSTOMER_PO_NO_UNIQUE,
    payload: {}
  });
  return axios
    .post(`${config.REACT_APP_API_BASE_URL}/is-customer-po-number-unique`, data, {
      headers: headers()
    })
    .then(response => {
      dispatch({
        type: IS_CUSTOMER_PO_NO_UNIQUE_FULFILLED,
        payload: response.data
      });
      return { error: false, response };
    })
    .catch(err => {
      dispatch({
        type: IS_CUSTOMER_PO_NO_UNIQUE_REJECTED,
        payload: err
      });
      return { error: true, err };
    });
};

export const fetchPoRefNo = () => dispatch => {
  dispatch({
    type: FETCH_PO_REF_NO,
    payload: {}
  });
  return axios
    .get(`${config.REACT_APP_API_BASE_URL}/latest-purchase-order`, { headers: headers() })
    .then(response => {
      dispatch({
        type: FETCH_PO_REF_NO_FULFILLED,
        payload: response.data
      });
      return { error: false, response };
    })
    .catch(err => {
      dispatch({
        type: FETCH_PO_REF_NO_REJECTED,
        payload: err
      });
      return { error: true, err };
    });
};

export const postPoRefNo = (data) => dispatch => {
  dispatch({
    type: POST_PO_REF_NO,
    payload: {}
  });
  return axios
    .post(`${config.REACT_APP_API_BASE_URL}/purchase-orders`, data, { headers: headers() })
    .then(response => {
      dispatch({
        type: POST_PO_REF_NO_FULFILLED,
        payload: response.data
      });
      return { error: false, response };
    })
    .catch(err => {
      dispatch({
        type: POST_PO_REF_NO_REJECTED,
        payload: err
      });
      return { error: true, err };
    });
};