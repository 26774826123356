import { FETCH_RECENT_ORDERS, FETCH_RECENT_ORDERS_FULFILLED, FETCH_RECENT_ORDERS_REJECTED } from '../actions'

export default (state = {
  data: null,
  fetching: false,
  fetched: false,
  error: null
}, action) => {
  switch (action.type) {
    case FETCH_RECENT_ORDERS:
      return { ...state, fetching: true, fetched: false, error: null }
    case FETCH_RECENT_ORDERS_FULFILLED:
      const payload = action.payload
      delete payload.variant // remove variant from filter options.
      return { ...state, fetching: false, fetched: true, error: null, data: payload }
    case FETCH_RECENT_ORDERS_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload, data: null }

    default:
      return state
  }
}
