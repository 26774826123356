import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Modal, Input, Button, Layout, Row, Col, Menu, Icon, Select, Spin, Skeleton, Result } from 'antd'
import { useMediaQuery } from 'react-responsive'
import {
  fetchPermissions,
  fetchDistributor,
  updateDistributor,
  submitImage,
} from '../../actions'
import {
  fetchMail,
  removeAdditionalEmail,
  updateAdditionalEmail,
  submitAdditionalEmail
} from '../../actions/additionalEmail'
import DocumentMeta from "react-document-meta";
import error from "../../assets/icons/error-icon.png";
import success from "../../assets/icons/check-icon.png";

import './UserProfile.scss'
const { Content } = Layout
class UserProfile extends Component {
  constructor() {
    super()
    this.imageUploader = React.createRef();
    this.state = {
      isShowUploadBtn: false,
      isShowUploadLoading: false,
      isSubmitLoading: false,
      updateEmail: ''
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const { form } = this.props;
    const keyAdditionalMailID = form.getFieldValue('keyAdditionalMailID');

    this.setState({ isSubmitLoading: true })
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let { mobile, email, customer_code, country, company_landline, address, port_destination, additional_mail } = values;
        address = (address) ? address.filter(function (e) { return e }) : []
        port_destination = (port_destination) ? values.port_destination.filter(function (e) { return e }).map(data => data.replace(/,/g, '')) : []
        additional_mail = (additional_mail) ? values.additional_mail.filter(function (e) { return e }) : []
        const submitData = {
          mobile, email, customer_code, company_landline, port_destination, address, country, additional_mail
        }
        const submitMail = { additional_mail }

        submitMail.additional_mail.forEach((res, index) => {
          if (this.validateEmail(res)) {
            const data = { email: res }
            this.props.submitAdditionalEmail(data)
          }

          const data = { id: keyAdditionalMailID[index], email: res }
          this.props.updateAdditionalEmail(data)
        })



        this.props.updateDistributor(submitData)
          .then(response => {
            if (response.error) {
              Modal.error({
                title: 'Unable to update your info. Please try again.',
                content: '',
                icon: <img src={error} alt="error" />
              })
            } else {
              Modal.success({
                title: 'Information Saved...',
                content: '',
                icon: <img src={success} alt="success" />
              })
            }
          })
          .then(() => {
            this.props.fetchDistributor()
            this.setState({ isSubmitLoading: false })
          })
      }
    });
  };

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  componentDidMount() {
    this.props.fetchPermissions()
    this.props.fetchDistributor()
  }

  isMobile() {
    return useMediaQuery({
      query: '(max-device-width: 810px)'
    })
  }


  handleSubmitImage = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ isShowUploadLoading: true })
        let media = null;
        let imageUrl =
          this.imageUploader.current === null
            ? ""
            : this.imageUploader.current.getImageUrl();
        media =
          this.imageUploader.current === null
            ? ""
            : await this.imageUploader.current.handleUploadRequest();
        const image = media ? media.url : imageUrl;
        await this.props.submitImage({ image: image })
          .then((response) => {
            if (response.error) {
              Modal.error({
                title: 'Unable to update Image Profile.',
                content: '',
                icon: <img src={error} alt="error" />
              })
            } else {
              Modal.success({
                title: 'Image Profile has been updated successfully',
                content: '',
                icon: <img src={success} alt="success" />
              })
            }
            this.setState({ isShowUploadLoading: false })
          })
      }
    });
  };

  render() {
    const {
      myPermissions,
      myPermissionsIsFetching
    } = this.props
    const { getFieldDecorator } = this.props.form;

    const meta = {
      title: "User Profile - URC Flour Division",
      description: "URC Flour Division seeks global awareness for its brands, enter new territories and explore untapped business opportunities. Learn more about us today!",
      canonical: window.location.href,
      meta: {
        charset: "utf-8",
        name: {
          keywords: "User Profile"
        }
      }
    };

    if (myPermissionsIsFetching) {
      return (
        <DocumentMeta {...meta}>
          <Layout>
            <Spin spinning={myPermissionsIsFetching}>
              <Skeleton active />
            </Spin>
          </Layout>
        </DocumentMeta>
      )
    }

    return (
      <DocumentMeta {...meta}>
        <Layout>
          {myPermissions.some(r => r.name === 'distributor.orders.view') ? <Layout>
            <Content className='user-profile-container'>
              <Layout>
                <Row type='flex' className='user-profile-card'>
                  <Col lg={6} md={24} sm={24}>
                    <Content className='side-container'>
                      <Content>
                        <div className='user-profile-sider-header'>
                          <div className='test' style={{ width: '100%', position: 'absolute', top: '45px', height: '180px', textAlign: 'center' }}>
                            <Form onSubmit={e => this.handleSubmitImage(e)}>
                              <div style={{ margin: 0 }}>
                                {this.state.isShowUploadBtn ?
                                  <Button
                                    htmlType='submit'
                                    icon="save"
                                    className="btn-small btn-primary"
                                    loading={this.state.isShowUploadLoading}
                                  >
                                    {this.state.isShowUploadLoading ? null : "Save"}
                                  </Button> : null
                                }
                              </div>

                              {/* } */}
                            </Form>
                          </div>
                        </div>
                      </Content>
                      <div className='sider-content'>
                        
                        <Content>
                          <Menu
                            mode="inline"
                            style={{ borderRight: 0, background: '#FCF9F4' }}
                            defaultSelectedKeys={['1']}
                          >
                            <Menu.Item key="1"> <span className='sidebar-label'>CUSTOMER INFORMATION</span></Menu.Item>
                            <Menu.Item key="2" onClick={() => this.props.history.push('/confirm-password')}> <span className='sidebar-label'>CHANGE PASSWORD</span></Menu.Item>

                          </Menu>
                        </Content>
                      </div>
                    </Content>
                  </Col>
                  <Col lg={18} md={24} sm={24}>
                    <Content className='user-profile-content'>
                      <Content>
                        <div className='user-profile-header'>
                          <div className='user-profile-header-text'>
                            <h1>User Profile</h1>
                            <h2>
                              {/* Edit Information > */}
                            </h2>
                          </div>
                        </div>
                      </Content>
                      <Content className='content-scrollable'>
                        <div className='user-profile-text-header'>
                          <h1>Customer Information</h1>
                        </div>
                        <Form className='user-profile-form' onSubmit={this.handleSubmit}>
                          <Row gutter={30}>
                            <Col md={12} sm={24}>
                              <h4>Customer Name</h4>
                              <Input style={disabledInputStyle} placeholder={`Company Name`} value={this.props.distributor.name || 'N/A'} disabled />
                            </Col>
                            <Col md={12} sm={24}>
                              <Form.Item className='form-item-container'>
                                <h4>Country</h4>
                                {getFieldDecorator(`country`, {
                                  initialValue: this.props.distributor.country,
                                  validateTrigger: ['onChange', 'onBlur'],
                                  rules: [
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: "Please input your Country  ",
                                    },
                                  ],
                                })(
                                  <Input style={disabledInputStyle} placeholder={`Country`} value={ this.props.distributor.country} disabled />
                                )}
                              </Form.Item>
                            </Col>
                            <Col md={12} sm={24}>
                              <h4>Customer Code</h4>
                              {getFieldDecorator(`customer_code`, {
                                initialValue: this.props.distributor.customer_code || 'N/A',
                                validateTrigger: ['onChange', 'onBlur'],
                                rules: [
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: "Please input  ",
                                  },
                                ],
                              })(<Input style={disabledInputStyle} placeholder={`Company Name`} disabled />)}
                            </Col>
                            
                            <Col md={24} sm={24}>
                              <h4>Address</h4>
                                {getFieldDecorator(`address`, {
                                  initialValue: this.props.distributor.address || 'N/A',
                                  validateTrigger: ['onChange', 'onBlur'],
                                  rules: [
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: "Please input  ",
                                    },
                                  ],
                                })(<Input style={disabledInputStyle} placeholder={`Company Name`} value={this.props.distributor.address} disabled />)}
                            </Col>
                          </Row>
                        </Form>
                      </Content>
                    </Content>
                  </Col>
                </Row>
              </Layout>
            </Content>
          </Layout>
            : <Result
              status="warning"
              title="You have no permission to view this page."
            />
          }
        </Layout >
      </DocumentMeta >
    )
  }
}


const selectStyle = {
  border: 'none',
  outline: '0',
  background: 'transparent',
  borderBottom: '2px solid #DFC8A5',
  borderTop: '10px solid black',
  borderTop: '0',
  boxShadow: '0 2px 2px -2px #DFC8A5',
  padding: 0,
  width: '100%',
  color: '#404040',
  fontSize: '16px',
  paddingBottom: 12,
  fontFamily: 'Montserrat'
}
const inputStyle = {
  border: '0',
  outline: '0',
  background: 'transparent',
  borderBottom: '2px solid #DFC8A5',
  boxShadow: '0 2px 2px -2px #DFC8A5',
  padding: 0,
  width: '100%',
  color: '#404040',
  fontSize: '16px',
  paddingBottom: 12,
  fontFamily: 'Montserrat'
}

const disabledInputStyle = {
  border: '0',
  outline: '0',
  background: 'transparent',
  borderBottom: 'none',
  boxShadow: 'none',
  padding: 0,
  width: '100%',
  color: '#404040',
  fontSize: '16px',
  paddingBottom: 12,
  fontFamily: 'Montserrat'
}

const UserProfileForm = Form.create({ name: 'userProfile_form' })(UserProfile)

function mapStateToProps(state) {
  let {
    permissions,
    distributor,
    mail
  } = state
  mail = (distributor.data) ? distributor.data.mail : ''
  distributor = (distributor.data) ? distributor.data.customer : ''

  return {
    distributor,
    mail,
    myPermissions: permissions.data,
    myPermissionsIsFetching: permissions.fetching
  }
}

export default connect(mapStateToProps, {
  fetchPermissions,
  fetchDistributor,
  updateDistributor,
  submitImage,
  fetchMail,
  updateAdditionalEmail,
  removeAdditionalEmail,
  submitAdditionalEmail
})(UserProfileForm)
