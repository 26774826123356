import _ from "lodash";

import React, { Component } from 'react';
import { Button, Table, Popover, Icon, Popconfirm, Upload, Alert, message } from 'antd'

const MAX_FILE_SIZE = 5

const ACCEPTED_FILE_TYPE = [
	"image/jpeg",
	"image/png",
	"image/jpg",
	"application/pdf"
];

class AttachmentUpload extends Component {
	constructor(props) {
		super(props)
		this.state = {
			fileList: [],
			alert: {
				message: [],
				visibility: false
			}
		};
	}

	onRemove = (id) => {
		const {
			fileList
		} = this.state

		this.setState({
			fileList: fileList.filter(file => file.uid !== id)
		})
	}

	onSubmit = () => {
		const {
			onSubmitCallback,
			selectedOrder,
		} = this.props

		const {
			fileList
		} = this.state

		onSubmitCallback(fileList, selectedOrder?.id);
	}

	onBeforeFileUpload = (file) => {
		const { fileList } = this.state;
		this.setState({
			alert: {
				message: [],
				visibility: false
			}
		}, () => {
			if (this.validateFileSize(file) || this.validateFileType(file)) {
				return false;
			}

			fileList.push(file)

			this.setState({
				fileList: fileList
			})
		});



		return false;
	}

	validateFileSize = (file) => {
		const { fileList } = this.state;
		const currentFileSize = _.map(fileList, 'size');
		const totalFileSize = _.reduce(currentFileSize, (sum, currentValue) => sum + currentValue, 0) + file.size;
		if (totalFileSize / 1024 / 1024 > MAX_FILE_SIZE) {
			this.setAlertVisibility(true, "Uploaded files has reached a maximum of 5MB - " + file.name)
			return true;
		}

		return false;
	}

	validateFileType = (file) => {
		if (_.includes(ACCEPTED_FILE_TYPE, file.type) === false) {
			this.setAlertVisibility(true, "File Type not Supported - " + file.name)
			return true;
		}

		return false;
	}

	setAlertVisibility = (isShown, message = null) => {
		const { alert } = this.state
		if (message) {
			alert.message.push(message)
		}

		this.setState({
			alert: {
				visibility: isShown,
				message: alert.message
			}
		})
	}

	render() {
		const {
			alert,
			fileList
		} = this.state;

		const columns = [
			{
				title: "Filename",
				dataIndex: "name",
				key: "name"
			},
			{
				title: "File Size",
				dataIndex: "size",
				key: "size",
			},
			{
				width: 120,
				title: "Actions",
				dataIndex: "uid",
				key: "",
				render: (id) => {
					return (<div>
						<Popover content={(<p>Delete</p>)}>
							<Popconfirm
								title="Are you sure delete this attachment?"
								onConfirm={() => this.onRemove(id)}
								okText="Confirm"
								cancelText="Cancel"
							>
								<span style={{ cursor: 'pointer', margin: 5 }}>
									<Icon type="delete" style={{ fontSize: '1.5em', color: "#A57271" }} />
								</span>
							</Popconfirm>
						</Popover>
					</div>)
				}
			},
		];
		const props = {
			beforeUpload: this.onBeforeFileUpload,
			showUploadList: false,
			multiple: true,
			accept: "image/jpeg, image/png, image/jpg, application/pdf"
		};

		return (<div>
			<Upload style={{ float: 'right' }} {...props}>
				<Button style={{ margin: "10px 0" }}>
					<Icon type="upload" /> Select File
				</Button>
			</Upload>
			{alert.visibility && <Alert
				message="Error"
				description={(
					<div>
						<ul>
							{alert.message.map((message, index) => (
								<li key={index}>{message}</li>
							))}
						</ul>
					</div>
				)}
				type="error"
				showIcon
				style={{ margin: "20px 0" }}
				closable={true}
				onClose={() => this.setAlertVisibility(false)}
			/>}
			<Table
				columns={columns}
				dataSource={fileList}
			/>

			<Popconfirm
				title="Are you sure you want to proceed with the attachment?"
				onConfirm={this.onSubmit}
				okText="Confirm"
				cancelText="Cancel"
			>
				<Button style={{ margin: "10px 0", float: "right" }} disabled={fileList.length < 1}>Submit</Button>
			</Popconfirm>
		</div>)
	}
}

export default AttachmentUpload
