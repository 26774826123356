const localStore = localStorage // linter should declared this as undefined.

export const getUserDataFromLocalStorage = (key) => {
  let localStorageData
  if (localStore.getItem('USER_AUTH_DATA') !== null) {
    localStorageData = JSON.parse(localStore.getItem('USER_AUTH_DATA'))[key]
  }
  return localStorageData
}

export const isLocalStorageEmpty = () => {
  let strResult
  if ((localStore.getItem('SESSION_TOKEN') !== null)) {
    strResult = true
  } else {
    strResult = false
  }
  return strResult
}

export const clearLocalStorage = () => {
  localStore.clear()
}

export const getToken = () => {
  return window.localStorage.getItem('SESSION_TOKEN')
}

export const headers = () => {
  return {
    "Authorization": `Bearer ${getToken()}`,
    "Accept": "application/json",
    "Content-Type": "application/json"
  }
}

export const numberFormat = (amount, decimalPlace = 2) => {
  if (isNaN(amount)) {
    return amount;
  }

  const pieces = parseFloat(amount)
    .toFixed(decimalPlace)
    .split("");
  let ii = pieces.length - 3;
  while ((ii -= 3) > 0) {
    pieces.splice(ii, 0, ",");
  }

  return pieces.join("");
};
