import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Layout, Row, Col, List, Menu, Icon } from 'antd'
import ScrollAnimation from 'react-animate-on-scroll';
import MediaQuery from 'react-responsive'
import * as _ from 'lodash'

import './Footer.scss'

function Footer({ paths, globalLinks, isLoggedIn }) {
  const filteredPaths = _.filter(paths, path => path.placements && path.placements.includes('Footer'));

  return (
    <Fragment>
      <MediaQuery minWidth={1224}>
        {/* <ScrollAnimation animateIn="fadeIn"> */}
        <Layout.Footer>
          <Row type="flex" gutter={30}>
            <Col span={5}>
              <div className='logo' />
            </Col>
            
          </Row>
        </Layout.Footer>
        {/* </ScrollAnimation> */}
      </MediaQuery>
    </Fragment>

  )
}

export default Footer
