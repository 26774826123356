import axios from 'axios';
import config from '../config';
import { headers } from '../utilities'

// Must have own file.
export const POST_MAIL = 'POST_MAIL'
export const POST_MAIL_FULFILLED = 'POST_MAIL_FULFILLED'
export const POST_MAIL_REJECTED = 'POST_MAIL_REJECTED'

export const UPDATE_MAIL = 'UPDATE_MAIL'
export const UPDATE_MAIL_FULFILLED = 'UPDATE_MAIL_FULFILLED'
export const UPDATE_MAIL_REJECTED = 'UPDATE_MAIL_REJECTED'

export const DELETE_MAIL = 'DELETE_MAIL'
export const DELETE_MAIL_FULFILLED = 'DELETE_MAIL_FULFILLED'
export const DELETE_MAIL_REJECTED = 'DELETE_MAIL_REJECTED'

export const FETCH_MAIL = 'FETCH_MAIL';
export const FETCH_MAIL_FULFILLED = 'FETCH_MAIL_FULFILLED';
export const FETCH_MAIL_REJECTED = 'FETCH_MAIL_REJECTED';

export const submitAdditionalEmail = (data) => {
  return dispatch => {
    dispatch({
      type: POST_MAIL,
      payload: {}
    })
    return axios.post(`${config.REACT_APP_API_BASE_URL}/mailing-list`, { ...data }, { headers: headers() })
      .then(function (response) {
        dispatch({
          type: POST_MAIL_FULFILLED,
          payload: response.data
        })
        return { error: false }
      })
      .catch(function (error) {
        dispatch({
          type: POST_MAIL_REJECTED,
          payload: error
        })
        return { error: true }
      })
  }
}

export const updateAdditionalEmail = (data) => {

  const id = data.id
  const emailInput = { email: data.email }

  return dispatch => {
    dispatch({
      type: UPDATE_MAIL,
      payload: {}
    })
    return axios.post(`${config.REACT_APP_API_BASE_URL}/mailing-list/${id}`, emailInput, { headers: headers() })
      .then(function (response) {
        dispatch({
          type: UPDATE_MAIL_FULFILLED,
          payload: response.data
        })
        return { error: false }
      })
      .catch(function (error) {
        dispatch({
          type: UPDATE_MAIL_REJECTED,
          payload: error
        })
        return { error: true }
      })
  }
}

export const removeAdditionalEmail = (data) => {
  return dispatch => {
    dispatch({
      type: DELETE_MAIL,
      payload: {}
    })
    return axios.post(`${config.REACT_APP_API_BASE_URL}/mailing-remove`, { ...data }, { headers: headers() })
      .then(function (response) {
        dispatch({
          type: DELETE_MAIL_FULFILLED,
          payload: response.data
        })
        return { error: false }
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_MAIL_REJECTED,
          payload: error
        })
        return { error: true }
      })
  }
}


export const fetchMail = (data) => dispatch => {
  dispatch({
    type: FETCH_MAIL,
    payload: {}
  });
  return axios.get(`${config.REACT_APP_API_BASE_URL}/mailing-list-distributor`, { headers: headers() })
    .then(response => {
      dispatch({
        type: FETCH_MAIL_FULFILLED,
        payload: response.data
      });
      return { error: false, response };
    })
    .catch(err => {
      dispatch({
        type: FETCH_MAIL_REJECTED,
        payload: err
      })
      return { error: true, err };
    });
}