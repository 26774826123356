import Axios from 'axios'
import config from '../config'
import { headers } from '../utilities'

// Must have own file.
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_FULFILLED = 'FORGOT_PASSWORD_FULFILLED'
export const FORGOT_PASSWORD_REJECTED = 'FORGOT_PASSWORD_REJECTED'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_FULFILLED = 'CHANGE_PASSWORD_FULFILLED'
export const CHANGE_PASSWORD_REJECTED = 'CHANGE_PASSWORD_REJECTED'

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_FULFILLED = 'RESET_PASSWORD_FULFILLED'
export const RESET_PASSWORD_REJECTED = 'RESET_PASSWORD_REJECTED'

export const forgotPassword = (data) => {
  return dispatch => {
    dispatch({
      type: FORGOT_PASSWORD,
      payload: {}
    })
    return Axios.post(`${config.REACT_APP_API_BASE_URL}/password/email`, { ...data })
      .then(function (response) {
        dispatch({
          type: FORGOT_PASSWORD_FULFILLED,
          payload: response.data
        })
        return { error: false }
      })
      .catch(function (error) {
        dispatch({
          type: FORGOT_PASSWORD_REJECTED,
          payload: error
        })
        return { error: true, message: error.response && error.response.data ? error.response.data.error_code : null }
      })
  }
}

export const changePassword = (data) => {
  return dispatch => {
    dispatch({
      type: CHANGE_PASSWORD,
      payload: {}
    })
    return Axios.post(`${config.REACT_APP_API_BASE_URL}/password/change`, { ...data }, { headers: headers() })
      .then(function (response) {
        dispatch({
          type: CHANGE_PASSWORD_FULFILLED,
          payload: response.data
        })
        return { error: false }
      })
      .catch(function (error) {
        dispatch({
          type: CHANGE_PASSWORD_REJECTED,
          payload: error
        })
        return { error: true, message: error.response && error.response.data ? error.response.data.error : null }
      })
  }
}


export const resetPassword = (data) => {
  return dispatch => {
    dispatch({
      type: RESET_PASSWORD,
      payload: {}
    })
    return Axios.post(`${config.REACT_APP_API_BASE_URL}/password/reset`, { ...data }, { headers: headers() })
      .then(function (response) {
        dispatch({
          type: RESET_PASSWORD_FULFILLED,
          payload: response.data
        })
        return { error: false }
      })
      .catch(function (error) {
        dispatch({
          type: RESET_PASSWORD_REJECTED,
          payload: error
        })
        return { error: true }
      })
  }
}
