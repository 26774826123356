import axios from 'axios';
import moment from "moment"
import config from '../config';
import { headers } from '../utilities'

export const FETCH_DISTRIBUTOR_ORDER_SUMMARY = 'FETCH_DISTRIBUTOR_ORDER_SUMMARY';
export const FETCH_DISTRIBUTOR_ORDER_SUMMARY_FULFILLED = 'FETCH_DISTRIBUTOR_ORDER_SUMMARY_FULFILLED';
export const FETCH_DISTRIBUTOR_ORDER_SUMMARY_REJECTED = 'FETCH_DISTRIBUTOR_ORDER_SUMMARY_REJECTED';


export const fetchDistributorOrderSummary = () => dispatch => {
    dispatch({
        type: FETCH_DISTRIBUTOR_ORDER_SUMMARY,
        payload: {}
    });
    return axios.get(`${config.REACT_APP_API_BASE_URL}/order-summary`, { headers: headers() })
        .then(response => {
            dispatch({
                type: FETCH_DISTRIBUTOR_ORDER_SUMMARY_FULFILLED,
                payload: response.data
            });
        })
        .catch(err => {
            dispatch({
                type: FETCH_DISTRIBUTOR_ORDER_SUMMARY_REJECTED,
                payload: err
            })
        });
    }