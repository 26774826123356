import * as _ from 'lodash';
import {
    CREATE_PAYMENT_ATTACHMENT, CREATE_PAYMENT_ATTACHMENT_FULFILLED, CREATE_PAYMENT_ATTACHMENT_REJECTED
} from '../actions';

export default (state = {
	fetching: false,
	downloading: false,
	data: null,
	error: null
}, action) => {
	switch (action.type) {
		case CREATE_PAYMENT_ATTACHMENT:
			return { ...state, fetching: true, error: null }
		case CREATE_PAYMENT_ATTACHMENT_FULFILLED:
			return {
				...state, fetching: false, error: null, data: action.payload
			};
		case CREATE_PAYMENT_ATTACHMENT_REJECTED:
			return { ...state, fetching: false, error: action.payload, data: null }
		default:
			return state;
	}
}
