import axios from 'axios';
import * as _ from 'lodash';

import config from '../config';

export const FETCH_CUSTOMER_PRICELIST = 'FETCH_CUSTOMER_PRICELIST';
export const FETCH_CUSTOMER_PRICELIST_FULFILLED = 'FETCH_CUSTOMER_PRICELIST_FULFILLED';
export const FETCH_CUSTOMER_PRICELIST_REJECTED = 'FETCH_CUSTOMER_PRICELIST_REJECTED';

export const FETCH_CUSTOMER_MATERIAL_PRICELIST = 'FETCH_CUSTOMER_MATERIAL_PRICELIST';
export const FETCH_CUSTOMER_MATERIAL_PRICELIST_FULFILLED = 'FETCH_CUSTOMER_MATERIAL_PRICELIST_FULFILLED';
export const FETCH_CUSTOMER_MATERIAL_PRICELIST_REJECTED = 'FETCH_CUSTOMER_MATERIAL_PRICELIST_REJECTED';

export const fetchCustomerPriceList = (customerCode) => dispatch => {
  dispatch({
    type: FETCH_CUSTOMER_PRICELIST,
    payload: {}
  });
  return axios.get(`${config.REACT_APP_API_PRICING_URL}/customer/${customerCode}/material`)
    .then(response => {
      dispatch({
        type: FETCH_CUSTOMER_PRICELIST_FULFILLED,
        payload: response.data
      });
      return response;
    })
    .catch(err => {
      dispatch({
        type: FETCH_CUSTOMER_PRICELIST_REJECTED,
        payload: err
      });
      return err;
    });
}

export const fetchCustomerMaterialPriceList = (customerCode, materialCode) => dispatch => {
  dispatch({
    type: FETCH_CUSTOMER_MATERIAL_PRICELIST,
    payload: {}
  });
  return axios.get(`${config.REACT_APP_API_PRICING_URL}/customer/${customerCode}/material/${materialCode}`)
    .then(response => {
      dispatch({
        type: FETCH_CUSTOMER_MATERIAL_PRICELIST_FULFILLED,
        payload: response.data
      });
      return response;
    })
    .catch(err => {
      dispatch({
        type: FETCH_CUSTOMER_MATERIAL_PRICELIST_REJECTED,
        payload: err
      });
      return err;
    });
}