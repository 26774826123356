import { GET_DATA_FAQ, GET_DATA_FAQ_FULFILLED, GET_DATA_FAQ_REJECTED, POST_FAQ_FEEDBACK, POST_FAQ_FEEDBACK_FULFILLED, POST_FAQ_FEEDBACK_REJECTED, POST_FAQ_FEEDBACK_DEFAULT } from '../actions'

export default (state = {
  data: null,
  fetching: false,
  fetched: false,
  error: null,
  faqFeedbackLoading: false,
  faqFeedbackSuccess: false,
  faqFeedbackRejected: false,
  faqFeedbackData: []
}, action) => {
  switch (action.type) {
    case GET_DATA_FAQ:
      return { ...state, fetching: true, fetched: false, error: null }
    case GET_DATA_FAQ_FULFILLED:
      const payload = action.payload
      return { ...state, fetching: false, fetched: true, error: null, data: payload }
    case GET_DATA_FAQ_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload, data: null }

    case POST_FAQ_FEEDBACK:
      return { ...state, faqFeedbackLoading: true, faqFeedbackSuccess: false, faqFeedbackRejected: null }
    case POST_FAQ_FEEDBACK_FULFILLED:
      return { ...state, faqFeedbackLoading: false, faqFeedbackSuccess: true, faqFeedbackRejected: null, faqFeedbackData: action.payload }
    case POST_FAQ_FEEDBACK_REJECTED:
      return { ...state, faqFeedbackLoading: false, faqFeedbackSuccess: false, faqFeedbackRejected: action.payload, faqFeedbackData: null }
    case POST_FAQ_FEEDBACK_DEFAULT:
      return { ...state, faqFeedbackLoading: false, faqFeedbackSuccess: false }
    default:
      return state
  }
}
