import React from "react";
import { Layout, Card, Modal, Row, Col, Button, Carousel } from "antd";
import moment from 'moment';
import { _ } from "core-js";
import {
  List
} from 'antd';
import MediaQuery from "react-responsive";
import { numFormat } from "../../../utilities/format";
import success from "../../../assets/icons/check-icon.png";

class SapOrderSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
    };
  }

  render() {
    const payment_term_mapping = {
      'ZFDO': 'Credit term',
      'ZFDL': 'Cash On Delivery',
      'ZCIA': 'Cash In Advance', // NOTE: remove in second round of testing
      'ZFCI': 'Cash In Advance',
      'ZFDK': 'Special Term'
    };

    const {
      isSapOrderSummaryModalShow,
      onCloseSapOrderSummaryModal,
      sapOrderSummary,
      proceedToFeedback
    } = this.props;
    const { selectedTab } = this.state;

    const onSOPChange = (e) => {
      this.setState({ selectedTab: e })
    }

    function getTotalPrice(items) {
      let total = _.sumBy(items, (itemDetails) => itemDetails.unit_price ? itemDetails.unit_price * itemDetails.quantity : 0);
      return total + (total * 0.12);
    }
    return (
      <Modal
        className="sap-order-summary"
        visible={isSapOrderSummaryModalShow}
        style={{ top: 20 }}
        onCancel={() => onCloseSapOrderSummaryModal()}
        footer={null}
        closable={false}
        width={950}
      >
        <Layout id="Clicked_Order_Submit">
          <Layout.Content className="order-delivery-header">
            <Row style={{ display: 'flex', gap: '10px' }}>
              <Col>
                <img src={success} alt="success" style={{ height: 60, width: 60, marginRight: '15px' }} />
              </Col>
              <Col>
                <h1>Thank you for your order! Order has been confirmed</h1>
                <h1 className="order-transaction-id">{sapOrderSummary[0]?.order_transaction_id}</h1>
                <span style={{ fontSize: '15px', fontStyle: 'italic' }}>* This confirmation of receipt of order is subject to stock availability. You may monitor the order status via Order History.</span>
              </Col>
            </Row>
          </Layout.Content>
          <Layout.Content className="sap-order-summary-content">
            <Card style={{ padding: "1vh 1vw" }}>
              <Layout.Content>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={12} xs={12}> <h3>Sold To</h3></Col>
                  <Col span={8}><h3>{sapOrderSummary[0]?.sold_to.name}</h3></Col>
                  <Col xl={4} lg={4} md={4} sm={12} xs={12}> <h3>Ship To</h3></Col>
                  <Col span={8}><h3>{sapOrderSummary[0]?.ship_to.name}</h3></Col>
                </Row>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={12} xs={12}> <h3>Sold To Code</h3></Col>
                  <Col span={8}><h3>{sapOrderSummary[0]?.sold_to.customer_code}</h3></Col>
                  <Col xl={4} lg={4} md={4} sm={12} xs={12}> <h3>Ship To Code</h3></Col>
                  <Col span={8}><h3>{sapOrderSummary[0]?.ship_to.customer_code}</h3></Col>
                </Row>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={12} xs={12}> <h3>Address</h3></Col>
                  <Col span={8}> <h3>{sapOrderSummary[0]?.ship_to.address + ', ' + sapOrderSummary[0]?.ship_to.city + ', ' + sapOrderSummary[0]?.ship_to.postal_code}</h3></Col>
                  <Col xl={4} lg={4} md={4} sm={12} xs={12}> <h3>Payment Term</h3></Col>
                  <Col span={8}><h3>{payment_term_mapping[sapOrderSummary[selectedTab]?.payment_term]}</h3></Col>
                </Row>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={12} xs={12}> <h3>Delivery Date</h3></Col>
                  <Col span={8}> <h3>{moment(sapOrderSummary[0]?.delivery_details.estimated_delivery_date).format('ll')} /
                    <span className="delivery-type">{sapOrderSummary[0]?.delivery_details.type}</span></h3></Col>
                  <Col xl={4} lg={4} md={4} sm={12} xs={12}> <h3>Reference No</h3></Col>
                  <Col span={8}> <h3>{sapOrderSummary[selectedTab]?.sales_order_number}</h3></Col>
                </Row>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={12} xs={12}><h3>Source</h3></Col>
                  <Col span={8}><h3>{sapOrderSummary[selectedTab]?.source.map((values) => values.description).join(', ')}</h3></Col>
                </Row>
                {sapOrderSummary[0]?.special_instruction ? (<Row>
                  <Col xl={4} lg={4} md={4} sm={12} xs={12}> <h3>Special Instruction</h3></Col>
                  <Col span={12}> <h3>{sapOrderSummary[0]?.special_instruction}</h3></Col>
                </Row>) : ""}

              </Layout.Content>
            </Card>
            <Card >
              <Layout.Content>
                <Carousel afterChange={onSOPChange}>
                  {_.map(sapOrderSummary, (orderSummary) => (
                    <List
                      bordered
                      itemLayout="horizontal"
                      dataSource={orderSummary.order_material}
                      className="order-summary-item-list"
                      renderItem={(field) => {
                        return (
                          <List.Item style={{ width: '100%' }}>
                            <Col span={24}>
                              <MediaQuery minWidth={1224}>
                                <Row
                                  gutter={24}
                                  type="flex"
                                  align="middle"
                                >
                                  <Col span={5} className="product-information">
                                    <span className="product-id">
                                      <h4>{field.material_details?.material_number}</h4>
                                      <h3>{field.material_details?.description}</h3>
                                    </span>
                                  </Col>
                                  <Col span={4} align="center">
                                    {numFormat("dot/comma", field?.unit_price + (field.unit_price * 0.12)) || "0.00"}
                                  </Col>
                                  <Col span={4} align="center">
                                    {numFormat("dot/comma", field?.unit_price) || "0.00"}
                                  </Col>
                                  <Col span={4} align="center">
                                    {/* QUANTITY IN KG */}
                                    {field.weight}
                                  </Col>
                                  <Col span={3} align="center">
                                    {field.quantity}
                                  </Col>
                                  <Col span={4} align="right">
                                    {numFormat("dot/comma", (field?.unit_price * parseInt(field.quantity))) || "0.00"}
                                  </Col>
                                </Row>
                              </MediaQuery>
                              <MediaQuery minWidth={600} maxWidth={1223}>
                                <Row
                                  gutter={24}
                                  type="flex"
                                  align="middle"
                                >
                                  <Col span={5} className="product-information">
                                    <span className="product-id">
                                      <h4>{field.material_details?.material_number}</h4>
                                      <h3>{field.material_details?.description}</h3>
                                    </span>
                                  </Col>
                                  <Col span={4} align="center">
                                    {numFormat("dot/comma", field?.unit_price) || "0.00"}
                                  </Col>
                                  <Col span={4} align="center">
                                    {numFormat("dot/comma", field?.unit_price + (field.unit_price * 0.12)) || "0.00"}
                                  </Col>
                                  <Col span={4} align="center">
                                    {field.weight}
                                  </Col>
                                  <Col span={3} align="center">
                                    {field.quantity}
                                  </Col>
                                  <Col span={4} align="right">
                                    {numFormat("dot/comma", ((field?.unit_price + (field?.unit_price * 0.12)) * parseInt(field.quantity))) || "0.00"}
                                  </Col>
                                </Row>
                              </MediaQuery>
                              <MediaQuery maxWidth={599}>
                                <Row
                                  gutter={24}
                                  type="flex"
                                  align="middle"
                                >
                                  <Col span={12} className="product-information">
                                    <Row>
                                      <Col span={24}>
                                        <h3>Product</h3>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={24}>
                                        <h3>{field.material_details?.material_number}</h3>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={24}>
                                        <h3>{field.material_details?.description}</h3>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={12}>
                                    <Row>
                                      <Col span={24} align="left">
                                        <h3>VAT-EX</h3>
                                      </Col>
                                      <Col span={24} align="left">
                                        {numFormat("dot/comma", field?.unit_price) || "0.00"}
                                      </Col>
                                      <Col span={24} align="left">
                                        <h3>VAT-IN</h3>
                                      </Col>
                                      <Col span={24} align="left">
                                        {numFormat("dot/comma", field?.unit_price + (field.unit_price * 0.12)) || "0.00"}
                                      </Col>
                                      <Col span={24} align="left">
                                        <h3>ORDERED QUANTITY</h3>
                                      </Col>
                                      <Col span={24} align="left">
                                        {field.quantity}
                                      </Col>
                                      <Col span={24} align="left">
                                        <h3>ORDERED AMOUNT</h3>
                                      </Col>
                                      <Col span={24} align="left">
                                        {numFormat("dot/comma", ((field?.unit_price + (field?.unit_price * 0.12)) * parseInt(field.quantity))) || "0.00"}
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </MediaQuery>
                            </Col>
                          </List.Item>
                        );
                      }}
                      header={
                        <MediaQuery minWidth={600}>
                          <Row gutter={24} style={{ backgroundColor: '#F8F8F8' }}>
                            <Col span={5}>
                              <h4>PRODUCT</h4>
                            </Col>
                            <Col span={8} align="right">
                              <Row>
                                <Col span={24} align="center"><h4>AMOUNT  (PHP/UNIT)</h4></Col>
                              </Row>
                              <Row>
                                <Col span={12} align="center">
                                  <h4>VAT-IN</h4>
                                </Col>
                                <Col span={12} align="center">
                                  <h4>VAT-EX</h4>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={4} align="center">
                              <h4>
                                QUANTITY (IN KG)
                              </h4>
                            </Col>
                            <Col span={3} align="center">
                              <h4>
                                ORDERED QUANTITY
                              </h4>
                            </Col>
                            <Col span={4} align="right">
                              <h4>
                                ORDERED AMOUNT (PHP)
                              </h4>
                            </Col>
                          </Row>
                        </MediaQuery>
                      }
                      footer={
                        <Row
                          type="flex"
                          justify="center"
                          align="middle"
                          className="fixed-b"
                        >
                          <Col span={24}>
                            <Row>
                              <Col span={13} align="right">
                                <span>
                                  Ordered Quantity
                                </span>
                              </Col>
                              <Col span={11} align="right">
                                <span>{_.sumBy(orderSummary?.order_material, (material => parseInt(material?.quantity)))}</span>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={13} align="right">
                                <span>
                                  TOTAL
                                </span>
                              </Col>
                              <Col span={11} align="right">
                                <span>{numFormat('dot/comma', orderSummary.order_price?.vat_sales + orderSummary.order_price?.discount)}</span>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={13} align="right">
                                <span>
                                  Less: Discount
                                </span>
                              </Col>
                              <Col span={11} align="right">
                                <span>{numFormat('dot/comma', orderSummary.order_price?.discount)}</span>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={13} align="right">
                                <span>
                                  Total, Net of Discount
                                </span>
                              </Col>
                              <Col span={11} align="right">
                                <span>{numFormat('dot/comma', orderSummary.order_price?.vat_sales)}</span>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={13} align="right">
                                <span>
                                  Add: VAT
                                </span>
                              </Col>
                              <Col span={11} align="right">
                                <span>{numFormat('dot/comma', orderSummary.order_price?.vat_amount)}</span>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={13} align="right">
                                <span>
                                  Total Amount
                                </span>
                              </Col>
                              <Col span={11} align="right">
                                <span>{numFormat('dot/comma', orderSummary.order_price?.total_amount)}</span>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={13} align="right">
                                <span>
                                  Less: EWT (if applicable)
                                </span>
                              </Col>
                              <Col span={11} align="right">
                                <span>{numFormat('dot/comma', orderSummary.order_price?.ewt_amount)}</span>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={13} align="right">
                                <span>
                                  Amount for Payment
                                </span>
                              </Col>
                              <Col span={11} align="right">
                                <span>{numFormat('dot/comma', (orderSummary.order_price?.total_amount - orderSummary.order_price?.ewt_amount))}</span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      }
                    />
                  ))}
                </Carousel>
                <Row
                  style={{ marginTop: '12px' }}
                  type="flex"
                  justify="space-between"
                  align="middle"
                  className="f-warning-submit"
                >
                  <Col span={24} align="right">
                    <Button
                      className="btn-small btn-primary"
                      onClick={proceedToFeedback}
                    >
                      Proceed
                    </Button>
                  </Col>
                </Row>
              </Layout.Content>
            </Card>
          </Layout.Content>
        </Layout>
      </Modal>
    );
  }
}

export default SapOrderSummary;