import * as _ from 'lodash';
import { FETCH_PO_STATUS, FETCH_PO_STATUS_FULFILLED, FETCH_PO_STATUS_REJECTED } from '../actions';

export default (state = {
	data: null,
	fetching: false,
	fetched: false,
	error: null
}, action) => {
	switch (action.type) {
		case FETCH_PO_STATUS:
			return { ...state, fetching: true, fetched: false, error: null, data: null }
		case FETCH_PO_STATUS_FULFILLED:
			return {
				...state, fetching: false, fetched: true, error: null,
				data: action.payload
			};
		case FETCH_PO_STATUS_REJECTED:
			return { ...state, fetching: false, fetched: false, error: action.payload, data: null };

		default:
			return state;
	}
}
