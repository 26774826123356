import axios from 'axios'

import config from '../config'
import { headers } from '../utilities'

export const FETCH_RECENT_ORDERS = 'FETCH_RECENT_ORDERS'
export const FETCH_RECENT_ORDERS_FULFILLED = 'FETCH_RECENT_ORDERS_FULFILLED'
export const FETCH_RECENT_ORDERS_REJECTED = 'FETCH_RECENT_ORDERS_REJECTED'

export const CANCEL_RECENT_ORDERS = 'CANCEL_RECENT_ORDERS'
export const CANCEL_RECENT_ORDERS_FULFILLED = 'CANCEL_RECENT_ORDERS_FULFILLED'
export const CANCEL_RECENT_ORDERS_REJECTED = 'CANCEL_RECENT_ORDERS_REJECTED'

export const fetchRecentOrders = () => dispatch => {
  dispatch({
    type: FETCH_RECENT_ORDERS,
    payload: {}
  })
  return axios.get(`${config.REACT_APP_API_BASE_URL}/orders`, { headers: headers() })
    .then(response => {
      dispatch({
        type: FETCH_RECENT_ORDERS_FULFILLED,
        payload: response.data
      })
    })
    .catch(err => {
      dispatch({
        type: FETCH_RECENT_ORDERS_REJECTED,
        payload: err
      })
    })
}


export const cancelRecentOrders = (id) => dispatch => {
  const data = {
    status: 0
  }
  dispatch({
    type: CANCEL_RECENT_ORDERS,
    payload: {}
  })
  return axios.post(`${config.REACT_APP_API_BASE_URL}/admin/change-order-status/${id}`, data, { headers: headers() })
    .then(response => {
      dispatch(fetchRecentOrders())
      dispatch({
        type: CANCEL_RECENT_ORDERS_FULFILLED,
        payload: response.data
      })
      return { error: false, result: response }
    })
    .catch(err => {
      dispatch({
        type: CANCEL_RECENT_ORDERS_REJECTED,
        payload: err
      })
      return { error: true, result: err }
    })
}
