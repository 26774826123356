import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { numFormat } from "../../utilities/format";
import {
  reduxForm
} from "redux-form";
import * as _ from "lodash";
import {
  Layout,
  Spin,
  Col,
  Table,
  Skeleton,
  Row,
  Button,
  message
} from "antd";
import DocumentMeta from "react-document-meta";
import MediaQuery from "react-responsive";

import {
  fetchPermissions,
  fetchOrderStatus,
  uploadProofOfPayment
} from "../../actions";
import "./Payment.scss";
import ProofOfPayment from "./ProofOfPayment";

class Payments extends Component {
  orderStatusArray = [];

  constructor() {
    super();
    this.state = {
      tableData: [],
      selectedRowKeys: [],
      showUploadModal: false,
    }
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  onSelectChange(selectedRowKeys) {
    this.setState({ selectedRowKeys });
  }

  componentDidMount() {
    this.props.fetchPermissions();
    this.fetchOrder();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.orderStatus.data !== nextProps.orderStatus.data) {
      this.setState({ tableData: nextProps.orderStatus.data });
    }
  }

  onUploadAttachment = (fileList, params) => {
    this.props.uploadProofOfPayment(fileList, params).then((response) => {
      if (response.error) {
        return message.error('Unable to upload attachment');
      }

      message.success('Successfully uploaded attachment');
      this.fetchOrder();
      this.setState({
        showUploadModal: false,
        selectedRowKeys: []
      })
    });
  }

  fetchOrder = () => {
    this.props.fetchOrderStatus(this.orderStatusArray, {
      all: true,
      filters: JSON.stringify({ "order_detail.status": ["Confirmed", "For Review", "Blocked"], "order_detail.payment_term": ["ZFCI"] }),
      withPayment: false
    })
  }

  render() {
    const {
      myPermissionsIsFetching,
      orderStatus,
      distributor
    } = this.props;

    const {
      showUploadModal,
      selectedRowKeys
    } = this.state;

    let dataSource = this.state.tableData || [];
    let orderStatusData = dataSource.data || [];

    const meta = {
      title: "Payments - URC Flour Division",
      description:
        "URC Flour Division seeks global awareness for its brands, enter new territories and explore untapped business opportunities. Learn more about us today!",
      canonical: window.location.href,
      meta: {
        charset: "utf-8",
        name: {
          keywords: "Payments"
        }
      }
    };

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };

    const payment_term = {
      'ZCIA': 'Cash In Advance', // NOTE: remove on round to of testing
      'ZFCI': 'Cash In Advance',
      'ZFDO': 'Credit Term',
      'ZFDL': 'Cash On Delivery',
      'ZFDK': 'Special Term'
    };

    let columns = [
      {
        title: "ORDER DATE",
        dataIndex: "created_at",
        key: "Order Date",
        sorter: (a, b) => { /* moment(a.poCreatedAt).unix() - moment(b.poCreatedAt).unix() */ },
        render: ({ }, value) => <span>{moment(value.created_at).format('YYYY-MM-DD')}</span>,
      },
      {
        width: 110,
        title: (
          <span style={{ padding: 0 }}>
            ORDER NUMBER
          </span>
        ),
        dataIndex: "order_transaction_id",
        sorter: (a, b) => { /* moment(a.poCreatedAt).unix() - moment(b.poCreatedAt).unix() */ },
        key: "Order Number",
        render: (value, i) => (
          <Link
            to={`/purchase-status?id=${i.id}`}
            style={{
              color: "#0785E3",
              fontWeight: "normal",
              fontFamily: "Montserrat",
              padding: 0,
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        width: 120,
        title: (
          <span style={{ padding: 0 }}>
            TRANSACTION REFERENCE NUMBER
          </span>
        ),
        dataIndex: "sales_order_number",
        key: "Transaction Reference Number",
        sorter: (a, b) => {
          // Number(a.poNumber.split("-").pop()) -
          // Number(b.poNumber.split("-").pop())
        },
        render: (value, i) => (
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0",
              }}
            >
              <Link
                to={`/purchase-status?id=${i.id}`}
                style={{
                  color: "#0785E3",
                  fontWeight: "normal",
                  fontFamily: "Montserrat",
                  // whiteSpace: "nowrap"
                }}
              >
                {value}
              </Link>
            </div>
          </div>
        ),
      },
      {
        title: "ORDER STATUS",
        dataIndex: "status",
        key: "ORDER STATUS",
        align: "center",
        render: (value, row) => {
          let statusClassName = row.status === 'Completed' ? 'invoiced' : 'cancelled'
          statusClassName = ['Submitted', 'Confirmed', 'Approved', 'Partially Dispatched', 'Blocked', 'For Review', 'For Salesman Approval'].includes(row.status) ? 'in-process' : statusClassName
          const status = ["For Review", "For Salesman Approval"].includes(row.status) ? "Confirmed" : row.status
          return (
            <div className="order-status-tag">
              <span className={statusClassName}>{status}</span>
            </div>
          );
        },
      },
      {
        title: (
          <span>
            DELIVERED TO
          </span>
        ),
        dataIndex: "sold_to.name",
        key: "Delivered To",
        align: "right",
        sorter: (a, b) => {
          // Number(a.poNumber.split("-").pop()) -
          // Number(b.poNumber.split("-").pop())
        },
      },
      {
        title: "CUSTOMER",
        dataIndex: "customer.name",
        key: "Customer",
        sorter: true
      },
      {
        title: "CUSTOMER GROUP",
        dataIndex: "customer.customer_group",
        key: "Customer Group",
        sorter: true
      },
      {
        title: "CUSTOMER GROUP 2",
        dataIndex: "customer.customer_group_2",
        key: "Customer Group 2",
        sorter: true
      },
      {
        title: (
          <span>
            ORDERED
            <br />
            QUANTITY
          </span>
        ),
        dataIndex: "total_quantity",
        key: "ORDERED QUANTITY",
        align: "right",
      },
      {
        title: (
          <span>
            QUANTITY (IN KG)
          </span>
        ),
        dataIndex: "order_material",
        key: "TOTAL WEIGHT",
        align: "right",
        render: (value) => {
          const total_weight = value.reduce(function (res, item) {
            return res + (item.weight);
          }, 0);
          return numFormat("dot/comma", total_weight || 0)
        }
      },
      {
        title: (
          <span>
            ORDERED Amount
            <br />
            (PHP VAT-IN)
          </span>
        ),
        dataIndex: "order_price",
        key: "AMOUNT (IN USD)",
        align: "right",
        render: (value) => <span>PHP {numFormat("dot/comma", (value?.vat_sales + value?.vat_amount) || 0)}</span>,
      },
      {
        title: (
          <span>
            AMOUNT FOR PAYMENT
          </span>
        ),
        dataIndex: "order_price",
        key: "amount_for_payment",
        align: "right",
        render: (value) => <span>PHP {numFormat("dot/comma", value?.total_amount - value?.ewt_amount) || "0.00"}</span>,
      },
      {
        title: "DELIVERY DATE",
        dataIndex: "delivery_details.estimated_delivery_date",
        key: "Delivery Date",
        align: "right",
      },
      {
        title: (<span>TYPE</span>),
        dataIndex: "delivery_details.type",
        key: "Type",
        align: "right",
        render: (value) => {
          return (<span style={{ textTransform: "capitalize" }}>{value}</span>)
        },
      },
      {
        title: (<span>SOURCE</span>),
        dataIndex: "shipping_point",
        key: "Source",
        render: (value) => {
          return value ? (
            <span>
              {value.map((shipping_point) => {
                return shipping_point.description
              }).join(', ')}
            </span>
          ) : ''
        }
      },
      {
        title: (<span>PAYMENT TERM</span>),
        dataIndex: "payment_term",
        key: "Payment Term",
        align: "left",
        render: ({ }, value) => {
          let color = value.status === "invoiced" ? " #479667" : "#EF9643";
          return (
            <span style={{ color: color, fontSize: 14, fontWeight: 600, overflowWrap: 'break-word', wordWrap: 'break-word' }}>
              {payment_term[value.payment_term]}
            </span>
          );
        },
      }
    ];

    if (myPermissionsIsFetching) {
      return (
        <DocumentMeta {...meta}>
          <Layout>
            <Spin spinning={myPermissionsIsFetching}>
              <Skeleton active />
            </Spin>
          </Layout>
        </DocumentMeta>
      )
    }

    if (distributor.data && distributor.data.customer.customer_group) {
      columns = columns.filter((value) => !_.includes(['CUSTOMER', 'CUSTOMER GROUP', 'CUSTOMER GROUP 2'], value.title))
    }

    return (
      <DocumentMeta {...meta}>
        <Layout>
          <Layout.Content className="order-status-content">
            <Row className='summary-header-first'>
              <Col span={8}>
                <h1>CIA Orders</h1>
              </Col>
            </Row>
            <Row style={{ margin: 10 }}>
              <Col xs={24} sm={24} md={14} lg={14} style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', width: "100%" }}>
                <Button
                  className="btn-small btn-secondary-gold"
                  style={{ width: "25%" }}
                  onClick={() =>
                    this.setState({ showUploadModal: true })
                  }
                  disabled={selectedRowKeys.length < 1}
                >
                  Upload Proof of Payment
                </Button>
              </Col>
            </Row>
            <Row className="order-status-row">
              <Col span={24} className="order-status-table">
                <Table
                  rowSelection={rowSelection}
                  dataSource={orderStatusData}
                  columns={columns}
                  rowKey="id"
                  style={{ overflow: "hidden", width: "100%", zIndex: 0 }}
                  loading={orderStatus.fetching}
                  pagination={false}
                />
              </Col>
            </Row>
            {/* <MediaQuery minWidth={1224}>
              <Row className='summary-header-first'>
                <Col span={8}>
                  <h1>CIA Orders</h1>
                </Col>
              </Row>
              <Row style={{ margin: 10 }}>
                <Col xs={24} sm={24} md={14} lg={14} style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', width: "100%" }}>
                  <Button
                    className="btn-small btn-secondary-gold"
                    style={{ width: "25%" }}
                    onClick={() =>
                      this.setState({ showUploadModal: true })
                    }
                    disabled={selectedRowKeys.length < 1}
                  >
                    Upload Proof of Payment
                  </Button>
                </Col>
              </Row>
            </MediaQuery> */}
            {/* <Row className="order-status-row">
              <MediaQuery minWidth={1024}>
                <Col span={24} className="order-status-table">
                  <Table
                    rowSelection={rowSelection}
                    dataSource={orderStatusData}
                    columns={columns}
                    rowKey="id"
                    style={{ overflow: "hidden", width: "100%", zIndex: 0 }}
                    loading={orderStatus.fetching}
                    pagination={false}
                  />
                </Col>
              </MediaQuery>
            </Row> */}
          </Layout.Content>
          {showUploadModal && (
            <ProofOfPayment
              isVisible={showUploadModal}
              onClose={() => {
                this.setState({ showUploadModal: false });
              }}
              onSubmitCallback={this.onUploadAttachment}
              selectedOrder={selectedRowKeys}
            />
          )}
        </Layout>
      </DocumentMeta>
    );
  }
}

const mapStateToProps = state => {
  const {
    permissions,
    orderStatus,
    distributor
  } = state;

  return {
    myPermissions: permissions.data,
    myPermissionsIsFetching: permissions.fetching,
    orderStatus,
    distributor
  };
};

export default connect(mapStateToProps, {
  fetchPermissions,
  fetchOrderStatus,
  uploadProofOfPayment
})(reduxForm({ form: "paymentForm", enableReinitialize: true })(Payments));
