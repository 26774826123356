import axios from "axios";
import { headers } from "../utilities";
import config from "../config";

export const GET_DATA_FAQ = "GET_DATA_FAQ";
export const GET_DATA_FAQ_FULFILLED = "GET_DATA_FAQ_FULFILLED";
export const GET_DATA_FAQ_REJECTED = "GET_DATA_FAQ_REJECTED";
export const POST_FAQ_FEEDBACK = "POST_FAQ_FEEDBACK";
export const POST_FAQ_FEEDBACK_FULFILLED = "POST_FAQ_FEEDBACK_FULFILLED";
export const POST_FAQ_FEEDBACK_REJECTED = "POST_FAQ_FEEDBACK_REJECTED";
export const POST_FAQ_FEEDBACK_DEFAULT = 'POST_FAQ_FEEDBACK_DEFAULT';


export const fetchFaq = (isLoggedIn) => dispatch => {
  let path = ''
  if (isLoggedIn) {
    path = 'active-faq-categories'
  } else {
    path = 'active-faq-categories-guest'
  }
  dispatch({
    type: GET_DATA_FAQ,
    payload: {}
  });
  return axios
    .get(`${config.REACT_APP_API_BASE_URL}/${path}`, {
      headers: headers()
    })
    .then(response => {
      dispatch({
        type: GET_DATA_FAQ_FULFILLED,
        payload: response.data
      });
      return { error: false, response };
    })
    .catch(err => {
      dispatch({
        type: GET_DATA_FAQ_REJECTED,
        payload: err
      });
      return { error: true, err };
    });
};


export const postFaqFeedback = (data) => dispatch => {
  dispatch({
    type: POST_FAQ_FEEDBACK,
    payload: {}
  });
  return axios
    .post(`${config.REACT_APP_API_BASE_URL}/faq-feedback`, { ...data }, {
      headers: headers()
    })
    .then(response => {
      dispatch({
        type: POST_FAQ_FEEDBACK_FULFILLED,
        payload: response.data
      });
      return { error: false, response };
    })
    .catch(err => {
      dispatch({
        type: POST_FAQ_FEEDBACK_REJECTED,
        payload: err
      });
      return { error: true, err };
    }).finally(() => {
      dispatch({
        type: POST_FAQ_FEEDBACK_DEFAULT,
      });
    })
};


