import React, { useState } from "react";
import { Layout } from "antd";
import PurchaseOrder from "./purchaseOrder";
// import PurchaseBreakdown from "./purchaseBreakdown";
import "./PurchaseOrder.scss";

const Purchase = () => {
  const [tabFrom, setTabFrom] = useState(3);
  const handleTabStatus = key => setTabFrom(key);

  return (
    <Layout>
      <PurchaseOrder getTabStatus={handleTabStatus} />
      {/* <PurchaseBreakdown tabFrom={tabFrom} /> */}
    </Layout>
  );
};

export default Purchase;
