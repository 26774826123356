import axios from 'axios';

import config from '../config';

export const FILTER_OPTIONS = 'FILTER_OPTIONS';
export const FILTER_OPTIONS_FULFILLED = 'FILTER_OPTIONS_FULFILLED';
export const FILTER_OPTIONS_REJECTED = 'FILTER_OPTIONS_REJECTED';

export const fetchFilterOptions = () => dispatch => {
  dispatch({
    type: FILTER_OPTIONS,
    payload: {}
  });
  return axios.get(`${config.REACT_APP_API_BASE_URL}/filter-option`, { params: {} })
    .then(response => {
      dispatch({
        type: FILTER_OPTIONS_FULFILLED,
        payload: response.data
      });
    })
    .catch(err => {
      dispatch({
        type: FILTER_OPTIONS_REJECTED,
        payload: err
      })
    });
}