import axios from 'axios';
import * as _ from 'lodash';

import config from '../config';

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCTS_FULFILLED = 'FETCH_PRODUCTS_FULFILLED';
export const FETCH_PRODUCTS_REJECTED = 'FETCH_PRODUCTS_REJECTED';

const initialFilter = { brand: 'all', product: 'all', country: 'all' };

export const fetchProducts = (filters = {}) => dispatch => {
  const params = _.mapValues(Object.assign({}, initialFilter, filters), filter => filter.join ? filter.join(',') : filter)
  dispatch({
    type: FETCH_PRODUCTS,
    payload: {}
  });
  return axios.get(`${config.REACT_APP_API_BASE_URL}/products-with-variants`, { params })
    .then(response => {
      dispatch({
        type: FETCH_PRODUCTS_FULFILLED,
        payload: response.data
      });
    })
    .catch(err => {
      dispatch({
        type: FETCH_PRODUCTS_REJECTED,
        payload: err
      })
    });
}