import axios from "axios";

import config from "../config";
import { headers } from "../utilities";

export const DELETE_ORDER_ATTACHMENT = "DELETE_ORDER_ATTACHMENT";
export const DELETE_ORDER_ATTACHMENT_FULFILLED = "DELETE_ORDER_ATTACHMENT_FULFILLED";
export const DELETE_ORDER_ATTACHMENT_REJECTED = "DELETE_ORDER_ATTACHMENT_REJECTED";

export const CREATE_ORDER_ATTACHMENT = "CREATE_ORDER_ATTACHMENT";
export const CREATE_ORDER_ATTACHMENT_FULFILLED = "CREATE_ORDER_ATTACHMENT_FULFILLED";
export const CREATE_ORDER_ATTACHMENT_REJECTED = "CREATE_ORDER_ATTACHMENT_REJECTED";

export const DOWNLOAD_ATTACHMENT = "DOWNLOAD_ATTACHMENT";
export const DOWNLOAD_ATTACHMENT_FULFILLED = "DOWNLOAD_ATTACHMENT_FULFILLED";
export const DOWNLOAD_ATTACHMENT_REJECTED = "DOWNLOAD_ATTACHMENT_REJECTED";

export const FETCH_FILE_URL = "FETCH_FILE_URL";
export const FETCH_FILE_URL_FULFILLED = "FETCH_FILE_URL_FULFILLED";
export const FETCH_FILE_URL_REJECTED = "FETCH_FILE_URL_REJECTED";

export const removeOrderAttachment = (orderId, attachmentId) => dispatch => {
	dispatch({
		type: DELETE_ORDER_ATTACHMENT,
		payload: {}
	});
	return axios
		.delete(
			`${config.REACT_APP_API_BASE_URL}/order/${orderId}/attachment/${attachmentId}`,
			{ headers: headers() }
		)
		.then(response => {

			dispatch({
				type: DELETE_ORDER_ATTACHMENT_FULFILLED,
				payload: response.data
			});

			return { error: false, response: response.data };
		})

		.catch(err => {

			dispatch({
				type: DELETE_ORDER_ATTACHMENT_REJECTED,
				payload: err
			});

			return { error: true, response: err };
		});
}

export const uploadOrderAttachment = (orderId, data) => dispatch => {
	dispatch({
		type: CREATE_ORDER_ATTACHMENT,
		payload: {}
	});

	const formData = new FormData();

	data.forEach(file => {
		formData.append("files[]", file);
	});

	return axios
		.post(
			`${config.REACT_APP_API_BASE_URL}/order/${orderId}/attachment`,
			formData,
			{ headers: { ...headers(), ...{ 'Content-Type': 'multipart/form-data' } } }
		)
		.then(response => {

			dispatch({
				type: CREATE_ORDER_ATTACHMENT_FULFILLED,
				payload: response.data
			});

			return { error: false, response: response.data };
		})

		.catch(err => {

			dispatch({
				type: CREATE_ORDER_ATTACHMENT_REJECTED,
				payload: err
			});

			return { error: true, response: err };
		});
}

export const downloadAttachment = (orderId, attachmentId) => dispatch => {
	dispatch({
		type: DOWNLOAD_ATTACHMENT,
		payload: {}
	});
	return axios
		.get(
			`${config.REACT_APP_API_BASE_URL}/order/${orderId}/attachment/${attachmentId}/download`,
			{ headers: headers(), responseType: 'blob' }
		)
		.then(response => {

			dispatch({
				type: DOWNLOAD_ATTACHMENT_FULFILLED,
				payload: response.data
			});

			return { error: false, response: response.data };
		})

		.catch(err => {

			dispatch({
				type: DOWNLOAD_ATTACHMENT_REJECTED,
				payload: err
			});

			return { error: true, response: err };
		});
}

export const retrieveFileUrl = (orderId, attachmentId) => dispatch => {
	dispatch({
		type: FETCH_FILE_URL,
		payload: {}
	});
	return axios
		.get(
			`${config.REACT_APP_API_BASE_URL}/order/${orderId}/attachment/${attachmentId}/file-url`,
			{ headers: headers() }
		)
		.then(response => {

			dispatch({
				type: FETCH_FILE_URL_FULFILLED,
				payload: response.data
			});

			return { error: false, response: response.data };
		})

		.catch(err => {

			dispatch({
				type: FETCH_FILE_URL_REJECTED,
				payload: err
			});

			return { error: true, response: err };
		});
}