import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import * as _ from "lodash";
import {
  Layout,
  Row,
  Col,
  Input,
  Select,
  Icon,
  List,
  Timeline,
  Popover,
  Result
} from "antd";
import DocumentMeta from "react-document-meta";
import { fetchDistributor } from "../../actions";
import sampleData from "../OrderStatus/data";
import { fetchPoStatus, fetchDataFromSap, fetchOrderById } from "../../actions";
import MediaQuery from "react-responsive";
import { reduxForm } from "redux-form";
import "./PurchaseOrder.scss";
import { numFormat } from "../../utilities/format";
const { Option } = Select;

class PurchaseOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      filterBy: "all",
      tableData: [],
      isScrolled: false,
      tab: 1,
    };
  }

  handleFilterBy = (filterBy) => {
    this.setState({ filterBy, keyword: "" });
    const { orderDetails } = this.props;
    const newArrayObj = orderDetails.data;

    // if data not yet loaded condition to avoid error
    if (!newArrayObj) {
      return;
    }

    // if data not yet loaded condition to avoid error
    if (!newArrayObj) {
      return;
    } else {
      // return original data
      return this.setState({ tableData: newArrayObj });
    }
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);

    const handleScroll = (e) => {
      var scrollPositionY = window.pageYOffset || document.body.scrollTop;

      this.setState({
        isScrolled: scrollPositionY > 120,
      });
    };
    document.addEventListener("scroll", handleScroll, { passive: true });
    this.props.fetchDataFromSap();
    this.props.fetchDistributor();
    this.props.fetchOrderById(urlParams.get('id'));
    this.props.fetchPoStatus();
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps) {
      if (this.props.orderDetails.data !== nextProps.orderDetails.data) {
        this.setState({ tableData: nextProps.orderDetails.data });
      }
    }


  }

  getDataBasedOnCustomerCode() {

    const urlParams = new URLSearchParams(window.location.search);

    const distributorCustomerCode =
      this.props.distributor &&
      this.props.distributor.data &&
      this.props.distributor.data.distributor.customer_code;
    let orderStatusArray = [];
    sampleData.map((data) => {
      if (data["Customer PO Code"] === distributorCustomerCode) {
        orderStatusArray.push({ ...data });
      }
    });
    return orderStatusArray;
  }

  // The first params is the search value, second param are the search keys in table
  handleSearch = (searchTerm, searchKey) => {
    this.setState({ keyword: searchTerm });

    let newData = [];
    _.forEach(this.props.orderDetails.data.order_material, (materialDetails) => {
      if (searchKey === 'all') {
        const description = materialDetails.material_details.description.toLowerCase()
        const materialNumber = materialDetails.material_details.material_number
        if (_.includes(description, searchTerm.toLowerCase()) || _.includes(materialNumber, searchTerm)) {
          newData.push(materialDetails);
        }
      } else {
        const material = materialDetails.material_details[searchKey].toLowerCase()
        if (_.includes(material, searchTerm.toLowerCase())) {
          newData.push(materialDetails);
        }
      }

    })

    this.setState({
      tableData: {
        ...this.state.tableData,
        order_material: newData
      }
    })
  };

  renderDotLoading(status, center) {
    const dotCount = 4;
    let i;
    let arrayCircle = [];
    let circleDataObject = {
      activeDot: 0,
      label: "",
    };
    if (status === "invoiced") {
      circleDataObject = {
        activeDot: 4,
        label: "INVOICED",
      };
    } else if (status === "forLoading") {
      circleDataObject = {
        activeDot: 3,
        label: "READY TO LOAD",
      };
    } else if (status === "produced") {
      circleDataObject = {
        activeDot: 2,
        label: "PRODUCED",
      };
    } else {
      circleDataObject = {
        activeDot: 1,
        label: "FOR PRODUCTION",
      };
    }
    for (i = 0; i < dotCount; i++) {
      arrayCircle.push(
        <div
          key={i}
          style={{
            backgroundColor:
              circleDataObject.activeDot > i ? "#6BBF59" : "lightgray",
            height: 12,
            width: 12,
            borderRadius: 12,
            margin: 8,
          }}
        />
      );
    }
    return (
      <div className="xs-activity">
        <h4 style={{ color: "gray", whiteSpace: "nowrap" }}>
          {circleDataObject.label}
        </h4>
        <div
          style={{ display: "flex", justifyContent: center }}
          className="dot"
        >
          {arrayCircle}
        </div>
      </div>
    );
  }

  handleTab = (key) => {
    if (key == 1) {
      this.setState({
        tab: 1,
      });
      this.props.getTabStatus(1);
    } else {
      this.setState({
        tab: 2,
      });
      this.props.getTabStatus(2);
    }
  };

  render() {
    const { filterBy, keyword } = this.state;

    const meta = {
      title: "Purchase Order - URC Flour Division",
      description:
        "URC Flour Division seeks global awareness for its brands, enter new territories and explore untapped business opportunities. Learn more about us today!",
      canonical: window.location.href,
      meta: {
        charset: "utf-8",
        name: {
          keywords: "Purchase Order",
        },
      },
    };

    const orderDetails = this.state.tableData || [];
    const dataSource = keyword ? this.state.tableData.order_material : orderDetails.order_material;
    if (orderDetails.length < 1) return false;

    const columns = [
      {
        label: "All",
        value: "all"
      },
      {
        label: "Material Number",
        value: "material_number"
      },
      {
        label: "Material Description",
        value: "description"
      },
    ];

    let payment_term = {
      'ZCIA': 'Cash In Advance', // Note: Removed on second round of testin
      'ZFCI': 'Cash In Advance',
      'ZFDO': 'Credit Term',
      'ZFDL': 'Cash On Delivery',
      'ZFDK': 'Special Term'
    };

    const total_price = orderDetails.order_material.reduce(function (res, item) {
      const price_with_tax = item.unit_price + (item.unit_price * 0.12)
      return res + (price_with_tax * item.quantity);
    }, 0);

    return (
      <DocumentMeta {...meta}>
        <Layout>

          <Layout.Content
            className="po-page-content"
            style={{ display: this.state.tab == 2 ? "none" : "" }}
          >
            <Row className="po-row">
              {/* Header START */}
              <Col span={24}>
                <Row type="flex" className="po-page-header">
                  <Col xs={24} sm={12} md={2} lg={1} xl={1} span={1}>
                    <Link to="/order-status">
                      <Icon type="arrow-left" />
                    </Link>
                  </Col>
                  <Col span={23}>
                    <Row type="flex">
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <div className="po-page-sub-header">
                          <span className="right-field">Sold To:</span>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <div className="po-page-sub-header">
                          <span className="value">{orderDetails.sold_to.name || 'N/A'}</span>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <div className="po-page-sub-header">
                          <span className="right-field">Ship To:</span>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <div className="po-page-sub-header">
                          <span className="value">{orderDetails.ship_to.name || 'N/A'}</span>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <div className="po-page-sub-header">
                          <span className="right-field">Sold To Code:</span>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <div className="po-page-sub-header">
                          <span className="value">{orderDetails.sold_to.customer_code || 'N/A'}</span>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <div className="po-page-sub-header">
                          <span className="right-field">Ship To Code:</span>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <div className="po-page-sub-header">
                          <span className="value">{orderDetails.ship_to.customer_code || 'N/A'}</span>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <div className="po-page-sub-header">
                          <span className="left-field">Payment Term:</span>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <div className="po-page-sub-header">
                          <span className="value">{payment_term[orderDetails.payment_term] || 'N/A'}</span>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <div className="po-page-sub-header">
                          <span className="right-field">Address:</span>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <div className="po-page-sub-header">
                          <span className="value">{orderDetails.ship_to?.address + ', ' + orderDetails.ship_to?.city + ', ' + orderDetails.ship_to?.postal_code || 'N/A'}</span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <div className="po-page-sub-header">
                          <span className="left-field">Source:</span>

                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <div className="po-page-sub-header">
                          <span className="value">{
                            orderDetails.shipping_point.map((shipping_point) => {
                              return shipping_point.description
                            }).join(', ')
                          }</span>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <div className="po-page-sub-header">
                          <span className="right-field">Delivery Date:</span>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <div className="po-page-sub-header">
                          <span className="value">{orderDetails.delivery_details.estimated_delivery_date}</span>
                        </div>
                      </Col>

                    </Row>
                    <Row type="flex">
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <div className="po-page-sub-header">
                          <span className="right-field">Special Instruction</span>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={6} xl={6}>

                      </Col>
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <div className="po-page-sub-header">
                          <span className="right-field">Transaction Reference Number:</span>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={6} xl={6}>
                        <div className="po-page-sub-header">
                          <span className="value">{orderDetails.sales_order_number || 'N/A'}</span>
                        </div>
                      </Col>
                    </Row>
                    <Row type="flex">
                      <Col>
                        <div className="po-page-sub-header">
                          <span className="value">{orderDetails.special_instruction}</span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row className="po-page-filters">
                  <Col xs={24} sm={12} md={12} lg={12} className="filter-fields">
                    <Input
                      type="text"
                      // size="large"
                      style={{ width: 300 }}
                      className="filter-inputs"
                      prefix={<Icon type="search" />}
                      value={keyword}
                      placeholder="Search Products"
                      onChange={(e) =>
                        this.handleSearch(
                          e.target.value,
                          filterBy
                        )
                      }
                    />

                    <Select
                      // size="large"
                      style={{ width: 200 }}
                      placeholder="Filter by"
                      className="filter-inputs"
                      defaultValue={[filterBy]}
                      onChange={(value) => this.handleFilterBy(value)}
                    >
                      {columns
                        .map((option) => (
                          <Option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </Option>
                        ))}
                    </Select>
                  </Col>
                </Row>
              </Col>
              {/* Header END */}

              {/* List START */}
              <MediaQuery minWidth={1225}>
                <Col className="po-page-table" span={24}>
                  <List
                    header={
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "#FAFAFA",
                          color: "black",
                          fontSize: 12,
                          fontWeight: 700,
                          wordBreak: 'break-all'
                        }}
                      >
                        <Row>
                          <Col span={2}>
                            PRODUCT
                          </Col>
                          <Col span={6}>

                          </Col>
                          <Col span={6}>
                            <Row>
                              <Col span={24} align="center">
                                AMOUNT  (PHP/UNIT)
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12} align="center">
                                VAT-IN
                              </Col>
                              <Col span={12} align="center">
                                VAT-EX
                              </Col>
                            </Row>
                          </Col>
                          <Col span={4}>Quantity (IN KG)</Col>
                          <Col span={3}>ORDERED QUANTITY</Col>
                          <Col span={3} align="right">
                            ORDERED AMOUNT (PHP)
                          </Col>
                        </Row>
                      </div>
                    }
                    footer={
                      <div style={{ color: "white" }}>
                        <Row>
                          <Col xl={11} lg={11} md={11} sm={24} xs={24} >
                            <h2 style={{ color: 'white' }}>Order Status</h2>
                            <Timeline style={{ color: 'white', paddingTop: '12px', paddingLeft: '20px' }} mode="left">
                              {
                                orderDetails.order_history.map((value, i) => {
                                  return ["For Review", "For Salesman Approval"].includes(value.status) ? "" : (
                                    <Timeline.Item color="green">{moment(value.created_at).format('MMM DD hh:mm a')} - {value.status} </Timeline.Item>
                                  )
                                })
                              }
                            </Timeline>
                          </Col>
                          <Col xl={13} lg={13} md={13} sm={24} xs={24}>
                            <Row>
                              <Col span={5}>
                                <div className="footer-label">
                                  <h1>TOTAL</h1>
                                </div>
                              </Col>

                              <Col span={2} align="right">
                                <div className="footer-label">
                                  <h1>
                                    {
                                      orderDetails.order_material.reduce(function (res, item) {
                                        return res + (item.weight);
                                      }, 0)
                                    }
                                  </h1>
                                </div>
                              </Col>
                              <Col span={7} align="right">
                                <div className="footer-label">
                                  <h1>
                                    {orderDetails.total_quantity}
                                  </h1>
                                </div>
                              </Col>
                              <Col span={10} align="right">
                                <div className="footer-label">
                                  <h1>
                                    {numFormat("dot/comma", orderDetails.order_price?.vat_sales + orderDetails.order_price?.discount || 0)}
                                  </h1>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={19}>
                                <div className="footer-label">
                                  <h1>Less: Discount</h1>
                                </div>
                              </Col>
                              <Col span={5} align="right">
                                <div className="footer-label">
                                  <h1>
                                    {numFormat("dot/comma", orderDetails.order_price?.discount || 0)}
                                  </h1>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={19}>
                                <div className="footer-label">
                                  <h1>Total, Net of Discount</h1>
                                </div>
                              </Col>
                              <Col span={5} align="right">
                                <div className="footer-label">
                                  <h1>
                                    {numFormat("dot/comma", orderDetails.order_price?.vat_sales) || "0.00"}
                                  </h1>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={19}>
                                <div className="footer-label">
                                  <h1>Add: VAT</h1>
                                </div>
                              </Col>
                              <Col span={5} align="right">
                                <div className="footer-label">
                                  <h1>
                                    {numFormat("dot/comma", orderDetails.order_price?.vat_amount || 0)}
                                  </h1>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={19}>
                                <div className="footer-label">
                                  <h1>Total Amount</h1>
                                </div>
                              </Col>
                              <Col span={5} align="right">
                                <div className="footer-label">
                                  <h1>
                                    {numFormat("dot/comma", orderDetails.order_price?.total_amount || 0) || "0.00"}
                                  </h1>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={19}>
                                <div className="footer-label">
                                  <h1>Less: EWT (If Applicable)</h1>
                                </div>
                              </Col>
                              <Col span={5} align="right">
                                <div className="footer-label">
                                  <h1>
                                    {numFormat("dot/comma", orderDetails.order_price?.ewt_amount || 0) || "0.00"}
                                  </h1>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={19}>
                                <div className="footer-label">
                                  <h1>Amount for Payment</h1>
                                </div>
                              </Col>

                              <Col span={5} align="right">
                                <div className="footer-label">
                                  <h1>
                                    {numFormat("dot/comma", (orderDetails.order_price?.total_amount - orderDetails.order_price?.ewt_amount) || 0) || "0.00"}
                                  </h1>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    }
                    bordered
                    loading={this.props.poStatus.fetching}
                    dataSource={dataSource}
                    renderItem={(item, i) => {
                      const price_with_tax = item.unit_price + (item.unit_price * 0.12);
                      return (
                        <List.Item>
                          <div style={{ width: "100%" }}>
                            <Row>
                              <Col span={5} style={{ wordBreak: 'break-all' }}>
                                {item.material_code}
                              </Col>
                              <Col span={3}>
                                {item.material_details.description}
                                {
                                  moment(item.created_at).isSame(item.updated_at) ? '' : (<Popover content={(<p>Material edited by sales admin</p>)}>
                                    <span style={{ cursor: 'pointer', margin: 5 }}>
                                      <Icon type="info-circle" theme="filled" />
                                    </span>
                                  </Popover>)
                                }
                              </Col>
                              <Col span={6}>
                                <Row>
                                  <Col span={12} align="center">
                                    {numFormat("dot/comma", price_with_tax) || "0.00"}
                                  </Col>
                                  <Col span={12} align="center">
                                    {numFormat("dot/comma", item.unit_price) || "0.00"}
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={4}>{item.weight}</Col>
                              <Col span={3}>{item.quantity}</Col>
                              <Col span={3} align="right">
                                {numFormat("dot/comma", item.unit_price * item.quantity) || "0.00"}
                              </Col>
                            </Row>
                          </div>
                        </List.Item>
                      )
                    }}
                  />
                </Col>
              </MediaQuery>

              <MediaQuery maxWidth={1224}>
                <Col className="po-page-table" span={24}>
                  <List
                    className="po-page-list"
                    footer={
                      <div style={{ color: "white" }} className="po-page-list-footer">
                        <Row>
                          <Col xl={11} lg={11} md={11} sm={24} xs={24} >
                            <h2 style={{ color: 'white' }}>Order Status</h2>
                            <Timeline style={{ color: 'white', paddingTop: '12px', paddingLeft: '20px' }} className="timeline" mode="left">
                              {
                                orderDetails.order_history.map((value, i) => {
                                  return ["For Review", "For Salesman Approval"].includes(value.status) ? "" : (
                                    <Timeline.Item color="green">{moment(value.created_at).format('MMM DD hh:mm a')} - {value.status} </Timeline.Item>
                                  )
                                })
                              }
                            </Timeline>
                          </Col>
                          <Col xl={13} lg={13} md={13} sm={24} xs={24}>
                            <Row>
                              <Col span={5}>
                                <div className="footer-label">
                                  <h1>TOTAL</h1>
                                </div>
                              </Col>

                              <Col span={2} align="right">
                                <div className="footer-label">
                                  <h1>
                                    {
                                      orderDetails.order_material.reduce(function (res, item) {
                                        return res + (item.weight);
                                      }, 0)
                                    }
                                  </h1>
                                </div>
                              </Col>
                              <Col span={7} align="right">
                                <div className="footer-label">
                                  <h1>
                                    {orderDetails.total_quantity}
                                  </h1>
                                </div>
                              </Col>
                              <Col span={10} align="right">
                                <div className="footer-label">
                                  <h1>
                                    {numFormat("dot/comma", orderDetails.order_price?.vat_sales + orderDetails.order_price?.discount || 0)}
                                  </h1>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={19}>
                                <div className="footer-label">
                                  <h1>Less: Discount</h1>
                                </div>
                              </Col>
                              <Col span={5} align="right">
                                <div className="footer-label">
                                  <h1>
                                    {numFormat("dot/comma", orderDetails.order_price?.discount || 0)}
                                  </h1>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={19}>
                                <div className="footer-label">
                                  <h1>Total, Net of Discount</h1>
                                </div>
                              </Col>
                              <Col span={5} align="right">
                                <div className="footer-label">
                                  <h1>
                                    {numFormat("dot/comma", total_price - orderDetails.order_price?.vat_sales) || "0.00"}
                                  </h1>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={19}>
                                <div className="footer-label">
                                  <h1>Add: VAT</h1>
                                </div>
                              </Col>
                              <Col span={5} align="right">
                                <div className="footer-label">
                                  <h1>
                                    {numFormat("dot/comma", orderDetails.order_price?.vat_amount || 0)}
                                  </h1>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={19}>
                                <div className="footer-label">
                                  <h1>Total Amount</h1>
                                </div>
                              </Col>
                              <Col span={5} align="right">
                                <div className="footer-label">
                                  <h1>
                                    {numFormat("dot/comma", orderDetails.order_price?.total_amount || 0) || "0.00"}
                                  </h1>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={19}>
                                <div className="footer-label">
                                  <h1>Less: EWT (If Applicable)</h1>
                                </div>
                              </Col>
                              <Col span={5} align="right">
                                <div className="footer-label">
                                  <h1>
                                    {numFormat("dot/comma", orderDetails.order_price?.ewt_amount || 0) || "0.00"}
                                  </h1>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={19}>
                                <div className="footer-label">
                                  <h1>Amount for Payment</h1>
                                </div>
                              </Col>

                              <Col span={5} align="right">
                                <div className="footer-label">
                                  <h1>
                                    {numFormat("dot/comma", (orderDetails.order_price?.total_amount - orderDetails.order_price?.ewt_amount) || 0) || "0.00"}
                                  </h1>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    }
                    bordered
                    loading={this.props.poStatus.fetching}
                    dataSource={dataSource}
                    renderItem={(item, i) => {
                      const price_with_tax = item.unit_price + (item.unit_price * 0.12);
                      return (
                        <List.Item>
                          <div style={{ width: "100%", padding: '12px' }}>
                            <Row>
                              <Col span={8}>
                                <Row>
                                  <Col span={24} style={{ fontWeight: 700 }}>
                                    PRODUCT
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={24}>
                                    {item.material_code}<br />
                                    {item.material_details.description}
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={16}>
                                <Row>
                                  <Col span={16} style={{ fontWeight: 700 }}>
                                    Amount (PHP/UNIT) VAT-EX
                                  </Col>
                                  <Col span={8}>
                                    {numFormat("dot/comma", item.unit_price) || "0.00"}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={16} style={{ fontWeight: 700 }}>
                                    Amount (PHP/UNIT) VAT-IN
                                  </Col>
                                  <Col span={8}>
                                    {numFormat("dot/comma", price_with_tax) || "0.00"}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={16} style={{ fontWeight: 700 }}>
                                    QUANTITY (IN KG)
                                  </Col>
                                  <Col span={8}>
                                    {item.weight}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={16} style={{ fontWeight: 700 }}>
                                    ORDERED QUANTITY
                                  </Col>
                                  <Col span={8}>
                                    {item.quantity}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={16} style={{ fontWeight: 700 }}>
                                    ORDERED AMOUNT (PHP)
                                  </Col>
                                  <Col span={8}>
                                    {numFormat("dot/comma", price_with_tax * item.quantity) || "0.00"}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </List.Item>
                      )
                    }}
                  />
                </Col>
              </MediaQuery>
              {/* List END */}
            </Row>
          </Layout.Content>
        </Layout>
      </DocumentMeta >
    );
  }
}

const mapStateToProps = (state) => {
  const { distributor, poStatus, sapData, orderDetails } = state;

  return {
    distributor: distributor,
    poStatus,
    sapData,
    orderDetails
  };
};

export default connect(mapStateToProps, {
  fetchDistributor,
  fetchPoStatus,
  fetchDataFromSap,
  fetchOrderById
})(
  reduxForm({ form: "poOrderStatus", enableReinitialize: true })(PurchaseOrder)
);
