import * as _ from 'lodash';
import {
	FETCH_ORDER_DETAILS,
	FETCH_ORDER_DETAILS_FULFILLED,
	FETCH_ORDER_DETAILS_REJECTED
} from '../actions';

export default (state = {
	data: null,
	fetching: false,
	fetched: false,
	error: null
}, action) => {
	switch (action.type) {
		case FETCH_ORDER_DETAILS:
			return { ...state, fetching: true, fetched: false, error: null }
		case FETCH_ORDER_DETAILS_FULFILLED:
			return {
				...state, fetching: false, fetched: true, error: null,
				data: action.payload
			};
		case FETCH_ORDER_DETAILS_REJECTED:
			return { ...state, fetching: false, fetched: false, error: action.payload, data: null };
		default:
			return state;
	}
}
