import axios from 'axios'

import config from '../config'
import { headers } from '../utilities'

export const POST_FEEDBACK = 'POST_FEEDBACK'
export const POST_FEEDBACK_FULFILLED = 'POST_FEEDBACK_FULFILLED'
export const POST_FEEDBACK_REJECTED = 'POST_FEEDBACK_REJECTED'

export const postFeedback = (data) => dispatch => {
    dispatch({
        type: POST_FEEDBACK,
        payload: {}
    })
    return axios.post(`${config.REACT_APP_API_BASE_URL}/feedback`, data, { headers: headers() })
        .then(response => {
            dispatch({
                type: POST_FEEDBACK_FULFILLED,
                payload: response.data
            });
            return { error: false, response };
        })
        .catch(err => {
            dispatch({
                type: POST_FEEDBACK_REJECTED,
                payload: err
            });
            return { error: true, err };
        })
}
