import { BEVERAGE_FILTER, REMOVE_BEVERAGE_FILTER } from '../actions'

const initialData = {
  redirectFilter: false,
}
export default function home(state = initialData, action) {
  switch (action.type) {
    case BEVERAGE_FILTER:
      return {
        ...state,
        redirectFilter: action.payload.data,
      }

    case REMOVE_BEVERAGE_FILTER:
      return {
        ...state,
        redirectFilter: false,
      }
    default:
      return {
        ...state
      }
  }
}
