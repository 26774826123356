import * as _ from 'lodash';
import { FETCH_LOGGED_IN_PRODUCTS, FETCH_LOGGED_IN_PRODUCTS_FULFILLED, FETCH_LOGGED_IN_PRODUCTS_REJECTED } from '../actions';

export default (state = {
  data: null,
  size: 0,
  fetching: false,
  fetched: false,
  error: null
}, action) => {
  switch (action.type) {
    case FETCH_LOGGED_IN_PRODUCTS:
      return { ...state, fetching: true, fetched: false, error: null }
    case FETCH_LOGGED_IN_PRODUCTS_FULFILLED:
	    const data = Object.assign({}, _.mapKeys(action.payload, 'id'));
      return { ...state, fetching: false, fetched: true, error: null, data, size: _.size(data)};
    case FETCH_LOGGED_IN_PRODUCTS_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload, data: null };
    default:
      return state;
  }
}